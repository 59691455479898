import React from 'react'
import { useFormikContext } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import { rationalNumbers } from 'Core/masks'

import { TextInputNew, Wrapper } from 'SRC/ui/FormElementsNew'

export const DiscountCardPrice = () => {
	const formik = useFormikContext()
  const { t } = useTranslation('common')

	const actualPrice = Number(formik.values.discountedPrice) !== -1
		? Number(formik.values.discountedPrice)
		: Number(formik.values.price) !== -1
			? Number(formik.values.price)
			: -1

	return (
		<Wrapper>
			<TextInputNew
				id='discountCardPrice'
				name='discountCardPrice'
				title={t('Cijena uz popust karticu')}
				isDisabled={!actualPrice || actualPrice === -1}
				value={formik.values.discountCardPrice}
				mask={rationalNumbers}
				allowedCharacters={['rational']}
				measure='€'
				onChange={async textValue => {
					const discountCardPricePercentage = formik.values.discount

					const discountCardPrice = Number(textValue)

					if (!discountCardPrice) return

					const updatedPercentage = 100 - ((discountCardPrice / actualPrice) * 100)

					if (Number(updatedPercentage) <= 99 && Number(updatedPercentage) >= 1) {
						const priceWithDiscountCard = Number(updatedPercentage) !== -1
							? actualPrice - (actualPrice * (Number(updatedPercentage) / 100))
							: actualPrice

						await Promise.all([
							formik.setFieldValue('discount', Number(updatedPercentage).toFixed(2), false),
							formik.setFieldValue('discountCardPrice', priceWithDiscountCard, false)
						])
					} else {
						await formik.setFieldValue('discountCardPrice', actualPrice)
					}

					if (!formik.touched.discountCardPrice) formik.setFieldTouched('discountCardPrice', true)
				}}
			/>
		</Wrapper>
	)
}
