import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'

const UpArrow = ({ onClick }) => <ReactSVG src='/icons/strelica-up.svg' onClick={onClick}
  beforeInjection={svg => {
    svg.setAttribute('style', 'width: 40px; height: 40px; cursor: pointer;')
  }} />

const DownArrow = ({ onClick }) => <ReactSVG src='/icons/strelica-down.svg' onClick={onClick}
  beforeInjection={svg => {
    svg.setAttribute('style', 'width: 40px; height: 40px; cursor: pointer;')
  }} />

const Drag = () => <ReactSVG src='/icons/drag.svg' beforeInjection={svg => {
  svg.setAttribute('style', 'width: 40px; height: 40px; cursor: pointer;')
}} />

export const Arrows = ({ hasUpArrow, hasDownArrow, onIncreasePriority, onDecreasePriority }) =>
  <div className='ads-add__photos-list-view-item-arrows'>
    {hasUpArrow ? <UpArrow onClick={onIncreasePriority} /> : null}
    <Drag />
    {hasDownArrow ? <DownArrow onClick={onDecreasePriority} /> : null}
  </div>

Arrows.propTypes = {
  hasUpArrow: PropTypes.bool.isRequired,
  hasDownArrow: PropTypes.bool.isRequired,
  onIncreasePriority: PropTypes.func.isRequired,
  onDecreasePriority: PropTypes.func.isRequired
}

UpArrow.propTypes = {
  onClick: PropTypes.func.isRequired
}

DownArrow.propTypes = {
  onClick: PropTypes.func.isRequired
}
