
import React from 'react'
import { connect } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'
import { useFormikContext, ErrorMessage } from 'formik'

import { getCountries, getGroupedCountries } from 'SRC/modules/geoObjects/countries/selectors'

import {
	getCitiesByCountryWithinRegions,
	getCitiesLoading
} from 'SRC/modules/geoObjects/cities/selectors'
import { fetchCitiesByCountryWithinRegions } from 'SRC/modules/geoObjects/cities/actions'

import { fetchLocationsByCity } from 'SRC/modules/geoObjects/locations/actions'
import { getLocationsByCity, getLocationsLoading } from 'SRC/modules/geoObjects/locations/selectors'

import { SelectDropdown, Wrapper } from 'SRC/ui/FormElementsNew'

import { Header } from '../Header'


const Geo = ({
	countries,
	allCountries,
	regionsWithCities,
	regionsWithCitiesLoading,
	locations,
	locationsLoading,
	fetchCitiesByCountryWithinRegions,
	fetchLocationsByCity
}) => {
	const [selectedCityRegion, setSelectedCityRegion] = React.useState(-1)

	const formik = useFormikContext()
  const { t } = useTranslation('common')

	React.useEffect(() => {
		const { initialValues } = formik

		if (initialValues && initialValues.region && initialValues.city) {
			setSelectedCityRegion(JSON.stringify({
				city: initialValues.city,
				region: initialValues.region
			}))

			if (Number(initialValues.city) !== -1) {
				fetchLocationsByCity(Number(initialValues.city))
			}
		}

		if (Number(initialValues.country) !== -1) {
			fetchCitiesByCountryWithinRegions(Number(initialValues.country))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const selectedCountry = formik.values.country
	const countryError = formik.touched.country && formik.errors.country
	const countryOptions = Array.isArray(countries)
		? countries.map(country => ({
			id: `${country.id}`,
			label: country.label,
			value: `${country.id}`,
			isGrouped: country.isGrouped,
			options: Array.isArray(country.options)
				? country.options.map(childCountry => ({
					id: `${childCountry.id}`,
					label: childCountry.label,
					value: `${childCountry.id}`
				}))
				: []
		}))
		: []

	const cityError = formik.touched.city && formik.errors.city
	const cityOptions = regionsWithCities && Array.isArray(regionsWithCities[selectedCountry])
		? regionsWithCities[selectedCountry].map(region => ({
				id: `${region.id}`,
				label: region.label,
				value: `${region.id}`,
				isGrouped: true,
				options: Array.isArray(region.cities)
					? region.cities.map(city => ({
						id: `${city.id}`,
						label: city.label,
						value: JSON.stringify({
							city: `${city.id}`,
							region: `${region.id}`
						})
					}))
					: []
		}))
		: []
	const cityDisabled = !formik.values.country || Number(formik.values.country) === -1 ||
		!cityOptions.length || regionsWithCitiesLoading

	const locationError = formik.touched.location && formik.errors.location
	const locationOptions = locations && formik.values.city && Number(formik.values.city) !== -1 &&
		Array.isArray(locations[formik.values.city])
		? locations[formik.values.city].map(location => ({
			id: `${location.id}`,
			label: location.label,
			value: `${location.value}`
		}))
		: []

	const locationsDisabled = !formik.values.city || Number(formik.values.city) === -1 ||
		!locationOptions.length || locationsLoading

	return (
		<div className='ads-add__location'>
			<Header title={t('Lokacija oglasa')} />

			<div className='ads-add__location-wrapper'>
				<Wrapper isError={!!countryError}>
					<SelectDropdown
						id='country'
						title={t('Država')}
						isDisabled={false}
						isSearchable
						isRequired
						value={formik.values.country || -1}
						options={countryOptions}
						onChange={async selectedCountry => {
							console.log('selectedCountry', selectedCountry)

							await formik.setFieldValue('country', selectedCountry)
							await formik.setFieldValue('city', -1)
							formik.setFieldValue('location', -1)
		
							if (!formik.touched.country) formik.setFieldTouched('country', true)
		
							if (Number(selectedCountry) !== -1) {
								fetchCitiesByCountryWithinRegions(Number(selectedCountry))
							}
						}}
					/>

					<ErrorMessage name='country' render={msg => <span className='form-group__error-message'>{msg}</span>} />
				</Wrapper>

				<Wrapper isError={!!cityError}>
					<SelectDropdown
						id='city'
						title={t('Grad')}
						isDisabled={cityDisabled}
						isSearchable
						isRequired
						value={selectedCityRegion}
						options={cityOptions}
						onChange={async value => {
							let parsedValue = -1

							try {
								parsedValue = JSON.parse(value)

								console.log('parsedValue', parsedValue)

								if (parsedValue === -1) parsedValue = { city: -1, region: -1 }

								if (Number(parsedValue.city) === -1) setSelectedCityRegion(-1)
								else setSelectedCityRegion(value)

								await formik.setFieldValue('city', parsedValue.city)
								await formik.setFieldValue('location', -1)
								formik.setFieldValue('region', parsedValue.region)

								if (!formik.touched.city) formik.setFieldTouched('city', true)

								if (Number(parsedValue.city) !== -1) {
									fetchLocationsByCity(Number(parsedValue.city))
								}
							} catch (err) {
								formik.setFieldValue('country', -1)
								formik.setFieldValue('city', -1)
								formik.setFieldValue('location', -1)
								formik.setFieldValue('region', -1)
								setSelectedCityRegion(-1)
							}
						}}
					/>
					<ErrorMessage name='city' render={msg => <span className='form-group__error-message'>{msg}</span>} />
				</Wrapper>

				<Wrapper isError={!!locationError}>
					<SelectDropdown
						id='location'
						title={t('Lokacija')}
						isDisabled={locationsDisabled}
						isSearchable
						isRequired
						value={formik.values.location || -1}
						options={locationOptions}
						onChange={async (location) => {
							await formik.setFieldValue('location', location)

							if (!formik.touched.location) formik.setFieldTouched('location', true)
						}}
					/>
					<ErrorMessage name='location' render={msg => <span className='form-group__error-message'>{msg}</span>} />
				</Wrapper>
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		countries: getGroupedCountries(state),
		allCountries: getCountries(state),
		regionsWithCities: getCitiesByCountryWithinRegions(state),
		regionsWithCitiesLoading: getCitiesLoading(state),
		locations: getLocationsByCity(state),
		locationsLoading: getLocationsLoading(state)
	}
}

Geo.defaultProps = {
	locations: {}
}

export default connect(mapStateToProps, {
	fetchCitiesByCountryWithinRegions,
	fetchLocationsByCity
})(Geo)
