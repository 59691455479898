import React from 'react'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { connect } from 'react-redux'
import { useFormikContext, ErrorMessage } from 'formik'

import { SelectDropdown, Wrapper } from 'SRC/ui/FormElementsNew'

export const MultiSelectSpecification = ({
	spec,
	updateRelatedSpecsVisibility
}) => {
	const formik = useFormikContext()

	if (!formik.values || !Array.isArray(formik.values.specifications)) return null

	const value = formik.values.specifications[spec.index].value || []

	let specError = ''

	if (Array.isArray(formik.touched.specifications) && formik.touched.specifications[spec.index]) {
		specError = Array.isArray(formik.errors.specifications) ? formik.errors.specifications[spec.index] : ''
	}

  return (
    <Wrapper isError={specError}>
			<SelectDropdown
				id={`dropdown-spec-${spec.index}`}
				title={spec.label}
				isSearchable
				isMultiple
				isRequired={spec.isRequired}
				checkedItems={value}
				options={spec.options}
				onChange={async selectedOptions => {
					console.log('selectedoptions', selectedOptions)
					await formik.setFieldValue(`specifications.${spec.index}.value`, selectedOptions)

					if (!formik.touched.specifications || !formik.touched.specifications[spec.index]) {
						formik.setFieldTouched(`specifications.${spec.index}`, true)
					}

					updateRelatedSpecsVisibility(spec, selectedOptions)
				}}
			/>

			<ErrorMessage
        name={`specifications.${spec.index}`}
        render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
      />
		</Wrapper>
  )
}
