import React from 'react'
import { SelectDropdown, Wrapper } from 'SRC/ui/FormElementsNew'

import { connect as formikConnect } from 'formik'
import { compose } from 'recompose'
import withTranslation from 'next-translate/withTranslation'
import config from 'SRC/config/config.json'

class PriceTypeForAddons extends React.Component {
	onPriceTypeForAddonsChange = priceTypeForAddons => {
		const { formik, auctionPriceType } = this.props
		const { adPriceTypes } = config.adForm
		const value = `${priceTypeForAddons}`

		console.log('onPriceTypeForAddonsChange priceTypeForAddons', priceTypeForAddons)
		if (!value) return

		switch (value) {
			case `${adPriceTypes.retail.id}`:
				formik.setFieldValue('typeOfPrice', -1)
				formik.setFieldValue('discount', -1)
				formik.setFieldValue('discountCardPrice', -1)
				formik.setFieldValue('priceFrom', -1)
				formik.setFieldValue('priceTo', -1)
				break
			case `${adPriceTypes.discountCard.id}`:
				formik.setFieldValue('typeOfPrice', -1)
				formik.setFieldValue('priceFrom', -1)
				formik.setFieldValue('priceTo', -1)
				break
			case `${adPriceTypes.fromTo.id}`:
				formik.setFieldValue('typeOfPrice', -1)
				formik.setFieldValue('price', -1)
				formik.setFieldValue('discountedPrice', -1)
				formik.setFieldValue('discount', -1)
				formik.setFieldValue('discountCardPrice', -1)
				break
			case `${adPriceTypes.from.id}`:
				formik.setFieldValue('typeOfPrice', -1)
				formik.setFieldValue('price', -1)
				
				formik.setFieldValue('discountedPrice', -1)
				
				formik.setFieldValue('discount', -1)
				formik.setFieldValue('discountCardPrice', -1)
				formik.setFieldValue('priceTo', -1)
				break
			case 'Aukcija':
				console.log('onPriceTypeForAddonsChange auctionPriceType', auctionPriceType)
				if (auctionPriceType) formik.setFieldValue('typeOfPrice', auctionPriceType.id)
				formik.setFieldValue('price', -1)
				formik.setFieldValue('discountedPrice', -1)
				formik.setFieldValue('discount', -1)
				formik.setFieldValue('discountCardPrice', -1)
				formik.setFieldValue('priceFrom', -1)
				formik.setFieldValue('priceTo', -1)
				break
			default:
				formik.setFieldValue('typeOfPrice', -1)
				formik.setFieldValue('price', -1)
				formik.setFieldValue('discountedPrice', -1)
				formik.setFieldValue('discount', -1)
				formik.setFieldValue('discountCardPrice', -1)
				formik.setFieldValue('priceFrom', -1)
				formik.setFieldValue('priceTo', -1)
				break
		}
	}

	render() {
		const { formik, options, i18n: { t } } = this.props

		return (
			<Wrapper>
				<SelectDropdown
					id='priceTypeForAddons'
					title={t('common:Vrsta cijene')}
					isSearchable={false}
					isOptional={false}
					value={formik.values.priceTypeForAddons}
					options={options}
					onChange={priceTypeForAddons => {
						console.log('PriceTypeForAddons selected', priceTypeForAddons)

						this.onPriceTypeForAddonsChange(priceTypeForAddons)
						formik.setFieldValue('priceTypeForAddons', priceTypeForAddons)
					}}
				/>
			</Wrapper>
		)
	}
}

const enhance = compose(
	formikConnect,
	withTranslation
)

export default enhance(PriceTypeForAddons)
