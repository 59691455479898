import React from 'react'
import PropTypes from 'prop-types'
import { TextInput } from 'SRC/ui/FormElements'

export const PricePercentage = ({ onChange }) => <TextInput
  id='pricePercentage'
  label='Popust'
  name='pricePercentage'
  onChange={onChange}
  allowedCharacters={['numbers']}
  measure='%'
/>

PricePercentage.propTypes = {
  onChange: PropTypes.func.isRequired
}
