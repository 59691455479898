import PropTypes from 'prop-types'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'

export const RemoveButton = ({onClick, index}) => {
  const { t } = useTranslation('common')

  return (
    <div className='form-group'>
      <label style={{color: 'transparent'}}>placeholder</label>
      <a className='obrisi-model-btn' onClick={onClick} data-index={index}>
        <span className='obrisi-model'>[-]</span> {t('Obriši model')}</a>
    </div>
  )
}

RemoveButton.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func
}

RemoveButton.defaultProps = {
  index: 0
}
