import React from 'react'
import { Header } from '../Header'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { connect as connectFormik } from 'formik'
import { Select, Hidden } from 'SRC/ui/FormElementsNew'
import { getCountries } from 'SRC/modules/geoObjects/countries/selectors'
import { getCities } from 'SRC/modules/geoObjects/cities/selectors'
import { getLocations } from 'SRC/modules/geoObjects/locations/selectors'
import { getRegions } from 'SRC/modules/geoObjects/regions/selectors'
import PropTypes from 'prop-types'
import { RegionsApi } from 'SRC/modules/geoObjects/regions/api'
import { CitiesApi } from 'SRC/modules/geoObjects/cities/api'
import { LocationsApi } from 'SRC/modules/geoObjects/locations/api'
import { FormikRequired } from 'SRC/core/validators'

export class Location extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isCitiesLoading: false,
      cities: this.prepareCities(this.props.regions, this.props.cities),
      isLocationsLoading: false,
      locations: this.prepareLocations(this.props.locations)
    }
  }

  componentDidMount () {
    const { formik } = this.props

    const { cities, locations } = this.state

    if (formik.values.country && (!Array.isArray(cities) || !cities.length)) {
      this.loadCitiesToState(formik.values.country)
    }

    if (formik.values.country && formik.values.city && (!Array.isArray(locations) || !locations.length)) {
      this.loadLocationsToState(formik.values.city)
    }
  }

  getCountryField = () => ({
    id: 'country',
    name: 'country',
    label: 'Država',
    options: this.prepareCountries(),
    onChange: this.onCountryChange,
    isRequired: true,
    selectClassName: 'ads-add__select',
    validate: FormikRequired
  })

  prepareCountries = () => this.props.countries.filter(country => !country.parent).map(country => {
    const isGrouped = Boolean(this.props.countries.filter(item => item.parent === country.id).length)
    return {
      id: country.id,
      label: country.label,
      isGrouped,
      isDisabled: false,
      value: country.id,
      options: isGrouped ? this.props.countries.filter(item => item.parent === country.id).map(item => ({
        value: item.id,
        isDisabled: false,
        label: item.label
      })) : null
    }
  })

  onCountryChange = async (e) => {
    const { formik } = this.props

    formik.setFieldValue('city', -1)
    formik.setFieldValue('location', -1)

    this.setState({ locations: [] })

    const country = Number(e.target.value)
    await this.loadCitiesToState(country)
  }

  loadCitiesToState = async country => {
    await this.setState({
      isCitiesLoading: true
    })

    const regionsApi = new RegionsApi()
    const citiesApi = new CitiesApi()
    const regions = await regionsApi.getRegionsByCountry(country)
    const cities = await citiesApi.getCitiesByCountry(country)
    const preparedCities = this.prepareCities(regions, cities)

    await this.setState({
      cities: preparedCities,
      isCitiesLoading: !preparedCities.length
    })
  }

  prepareCities = (regions = [], cities = []) => regions.map(region => ({
    id: region.id,
    label: region.label,
    value: `region-${region.id}`,
    isGrouped: true,
    options: cities.filter(city => city.region === region.id).map(city => ({
      id: city.id,
      label: city.label,
      value: city.value,
      region: city.region
    }))
  }))

  getCityField = () => {
    const { formik } = this.props
    const { cities, isCitiesLoading } = this.state

    const isDisabled = !formik.values.country || formik.values.country === -1 || !Array.isArray(cities) ||
      !cities.length || isCitiesLoading

    return {
      id: 'city',
      name: 'city',
      label: 'Grad',
      options: this.state.cities,
      onChange: this.onCityChange,
      disabled: isDisabled,
      isRequired: true,
      selectClassName: 'ads-add__select',
      validate: FormikRequired
    }
  }

  getRegionField = () => ({id: 'region', name: 'region'})

  onCityChange = async e => {
    const { formik } = this.props

    formik.setFieldValue('location', -1)

    this.setState({ locations: [] })

    const city = Number(e.target.value)
    this.saveRegion(city)
    await this.loadLocationsToState(city)
  }

  saveRegion = city => {
    const arCity = this.state.cities.filter(item => item.options.some(option => option.id === city))
    const selectedRegion = arCity.length ? `${arCity[0].id}` : null
    this.props.formik.setFieldValue('region', selectedRegion)
  }

  getLocationField = () => {
    const { formik } = this.props
    const { locations, isLocationsLoading } = this.state

    const isDisabled = !formik.values.city || formik.values.city === -1 || !Array.isArray(locations) ||
      !locations.length || isLocationsLoading

    return {
      id: 'locations',
      name: 'location',
      label: 'Lokacija',
      options: this.state.locations,
      disabled: isDisabled,
      isRequired: true,
      selectClassName: 'ads-add__select',
      validate: FormikRequired
    }
  }

  loadLocationsToState = async city => {
    await this.setState({
      isLocationsLoading: true
    })

    const api = new LocationsApi()
    const locations = await api.getLocationsByCity(Number(city))

    this.setState({
      locations: this.prepareLocations(locations),
      isLocationsLoading: false
    })
  }

  prepareLocations = (locations = []) => {
    const sortedLocations = locations.sort((a, b) => {
      if (a.priority === b.priority) return 0
      else if (a.priority === null) return 1
      else if (b.priority === null) return -1
      else return a.priority - b.priority
    })
    return sortedLocations.map(({id, label, value}) => ({id, label, value}))
  }

  render () {
    const countryField = this.getCountryField()
    const cityField = this.getCityField()
    const locationField = this.getLocationField()

    return (
      <div className='ads-add__location'>
        <Header title={this.props.title} />
        <Select {...countryField} />
        <Hidden name={this.getRegionField().name} />
        <Select {...cityField} />
        <Select {...locationField} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  countries: getCountries(state),
  regions: getRegions(state),
  cities: getCities(state),
  locations: getLocations(state)
})

Location.propTypes = {
  title: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    parent: PropTypes.number,
    id: PropTypes.number.isRequired
  })).isRequired,
  regions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired
  })),
  cities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired
  })),
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired
  })),
  formik: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }).isRequired
}

Location.defaultProps = {
  title: 'Lokacija oglasa',
  countries: [],
  cities: [],
  locations: []
}

export default compose(
  connect(mapStateToProps),
  connectFormik
)(Location)
