import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import withTranslation from 'next-translate/withTranslation'
import { connect as formikConnect } from 'formik'

import { naturalNumbers, rationalNumbers } from 'Core/masks'
import config from 'SRC/config/config.json'


import { getCurrentSubCategory, getSpecifications } from 'SRC/modules/categories/selectors'

import { getSpecsWithUpdatedVisibility, getConditionTrusty, getIsVisible } from 'SRC/modules/ads/shared/functions'

import { SelectDropdown, Wrapper, AdFormHeader } from 'SRC/ui/FormElementsNew'

import { TextSpecification } from '../TextSpecification'
import { SingleSelectSpecification } from '../SingleSelectSpecification'
import { MultiSelectSpecification } from '../MultiSelectSpecification'
import { DateSpecification } from '../DateSpecification'
import { StarSpecification } from '../StarSpecification'

const sortSpecifications = (fields = []) => fields.slice().sort((a, b) => a.weightAdd - b.weightAdd)

const getTextInputMask = allowedCharacters => {
	let mask = null

	if (!Array.isArray(allowedCharacters)) return null
	if (['rational', 'natural', 'numbers'].some(ac => allowedCharacters.includes(ac)) && allowedCharacters.length === 1) {
		
		if (allowedCharacters.includes('rational')) mask = rationalNumbers
		else if (allowedCharacters.includes('natural')) mask = naturalNumbers
		else mask = null
	}

	return mask
}

const getRelatedSpec = (spec, specs) => {
	if (spec.type !== 'input') return null

	const dependentId = spec.dependent

	if (!dependentId) return null

	const findSpec = Array.isArray(specs) ? specs.find(s => s.specification.id === dependentId) : null

	if (findSpec) {
		return {
			dependentIndex: findSpec.index,
			dependentType: spec.dependentType,
			dependentRatio: spec.dependentRatio
		}
	}

	return null
}

class Container extends Component {
	prepareSpecifications = () => {
		const { specifications, formik } = this.props

		const preparedSpecs = []

		specifications.forEach(item => {
			const specInForm = formik.values.specifications[item.index]

			if (item.skip) return

			preparedSpecs.push({
				id: `specification-${item.specification.id}`,
				label: item.specification.title,
				index: item.index,
				name: `specifications[${item.index}].value`,
				isRequired: Boolean(item.required),
				type: ['select', 'multiselect', 'date', 'stars'].includes(item.specification.type)
					? item.specification.type
					: 'text',
				hint: item.specification.hint,
				placeholder: item.specification.placeholder,
				measure: item.specification.measure,
				options: ['select', 'multiselect'].includes(item.specification.type)
					? item.specification.options.map(option => ({
							id: `${item.specification.id}-${option}`,
							label: option,
							value: `${option}`
						}))
					: null,
				allowedCharacters: item.specification.allowedCharacters,
				relatedSpec: getRelatedSpec(item.specification, specifications),
				mask: getTextInputMask(item.specification.allowedCharacters),
				specification: item.specification,
				isHidden: specInForm ? specInForm.isHidden : true
			})
		})

		return preparedSpecs
	}

	updateRelatedSpecsVisibility = async (spec, value) => {
		const { specifications, formik, formType } = this.props

		const currentSpecificationValues = [...formik.values.specifications]

		const updatedSpecificationValues = getSpecsWithUpdatedVisibility({
			categorySpecifications: specifications,
			categorySpecification: spec,
			initialSpecificationValues: currentSpecificationValues,
			specificationValue: value,
			formType
		})

		await formik.setFieldValue('specifications', updatedSpecificationValues)
	}

	render() {
		const preparedSpecifications = this.prepareSpecifications()
		const nonStarsSpecs = Array.isArray(preparedSpecifications)
			? preparedSpecifications.filter(spec => spec.type !== 'stars')
			: []


		const starsSpecs = Array.isArray(preparedSpecifications)
			? preparedSpecifications.filter(spec => spec.type === 'stars')
			: []

		const { i18n: { t } } = this.props

		console.log('starsSpecs', starsSpecs)

		return (
			<React.Fragment>
				{nonStarsSpecs.map(specification => {
					if (!specification.isHidden) {
						const specProps = {
							spec: specification
						}

						switch(specification.type) {
							case 'text': {
								return <TextSpecification key={specification.id} {...specProps} />
							}
							case 'select': {
								specProps.updateRelatedSpecsVisibility = this.updateRelatedSpecsVisibility

								return <SingleSelectSpecification key={specification.id} {...specProps} />
							}
							case 'multiselect': {
								specProps.updateRelatedSpecsVisibility = this.updateRelatedSpecsVisibility

								return <MultiSelectSpecification key={specification.id} {...specProps} />
							}
							case 'date': return <DateSpecification key={specification.id} {...specProps} />
							default: return null
						}
					}
					return null
				})}

				{/* STAR */}
				{starsSpecs.length ? (
					<React.Fragment>
						<AdFormHeader title={t("common:OCIJENITE VOZILO")} />
						{starsSpecs.map(specification => <StarSpecification key={`star-spec-${specification.id}`} spec={specification} />)}
					</React.Fragment>
				) : null}
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const specifications = getSpecifications(state)

	const { hideSpecs, showOnlySpecs } = ownProps

  let categorySpecifications = Array.isArray(specifications) ? [...specifications] : []

  if (Array.isArray(showOnlySpecs) && showOnlySpecs.length) {
		categorySpecifications = categorySpecifications.map(item => {
			if (showOnlySpecs.includes(item.specification.title)) return item

			return {
				...item,
				skip: true
			}
		})
	}

	if (Array.isArray(hideSpecs) && hideSpecs.length) {
		categorySpecifications = categorySpecifications.map(item => {
			if (!hideSpecs.includes(item.specification.title)) return item

			return {
				...item,
				skip: true
			}
		})
	}

	const specs = categorySpecifications.map((item, index) => ({
    ...item,
    index
  }))

	return {
		currentSubCategory: getCurrentSubCategory(state),
		specifications: sortSpecifications(specs)
	}
}

const enhance = compose(
	connect(mapStateToProps),
	formikConnect,
	withTranslation
)

Container.displayName = 'SpecificationContainer'

export default enhance(Container)
