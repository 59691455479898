import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import { TextInput } from 'SRC/ui/FormElements'
import { priceMask } from 'Core/masks'
import { ReduxFormRequired } from 'Core/validators/reduxForm'

class PriceTo extends React.Component {
  componentDidUpdate () {
    const { priceToMasked } = this.props
    this.props.change(this.props.formName, 'priceToUnmasked', priceToMasked.toString().replace(',', '.'))
  }

  shouldComponentUpdate (nextProps) {
    return nextProps.priceToMasked !== null && Number(this.props.priceToMasked) !== Number(nextProps.priceToMasked)
  }
  render () {
    return [<TextInput
      id='priceTo'
      label='Cijena do'
      isRequired={this.props.isRequired}
      name='priceTo'
      measure='€'
      mask={priceMask}
      validate={this.props.isRequired ? ReduxFormRequired : undefined}
    />, <input id='priceToUnmaksed' type='hidden' name='priceToUnmaksed' />]
  }
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.formName)
  return {
    priceToMasked: selector(state, 'priceTo')
  }
}

PriceTo.propTypes = {
  priceToMasked: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  isRequired: PropTypes.bool.isRequired
}

PriceTo.defaultProps = {
  priceToMasked: '',
  isRequired: true
}

export default connect(mapStateToProps, {change})(PriceTo)
