import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'

const RotateRightIcon = ({ onClick }) => <ReactSVG src='/icons/rotate-right.svg' onClick={onClick}
  beforeInjection={svg => {
    svg.setAttribute('style', 'cursor: pointer;')
  }} />

export const RotateRightBtn = ({ onClick }) => <div className='ads-add__photos-list-view-item-rotate-btn'>
  <RotateRightIcon onClick={onClick} />
</div>

RotateRightBtn.propTypes = {
  onClick: PropTypes.func.isRequired
}

RotateRightIcon.propTypes = {
  onClick: PropTypes.func.isRequired
}
