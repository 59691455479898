import React from 'react'
import { debounce } from 'throttle-debounce'
import { connect } from 'react-redux'

import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { useFormikContext, ErrorMessage } from 'formik'

import { TextInputNew, Wrapper } from 'SRC/ui/FormElementsNew'

export const TextSpecification = ({ spec }) => {
	const formik = useFormikContext()

	if (!formik.values || !Array.isArray(formik.values.specifications)) return null

	const fieldName = `specifications.${spec.index}`

	let inputValue = formik.values.specifications[spec.index].value
	if (typeof inputValue === 'undefined') inputValue = -1

	let specError = ''

	if (Array.isArray(formik.touched.specifications) && formik.touched.specifications[spec.index]) {
		specError = Array.isArray(formik.errors.specifications) ? formik.errors.specifications[spec.index] : ''
	}

	const onChange = async textValue => {
		formik.setFieldValue(`${fieldName}.value`, `${textValue}`)

		if (spec.relatedSpec && spec.relatedSpec.dependentType === 'same') {
			const newValue = Math.round(Number(textValue) * Number(1 / spec.relatedSpec.dependentRatio)).toString()
			const isNumber = !isNaN(newValue)
			if (isNumber) {
				formik.setFieldValue(`specifications.${spec.relatedSpec.dependentIndex}`, {
					...formik.values.specifications[spec.relatedSpec.dependentIndex],
					value: `${newValue}`
				})
			} else {
				await formik.setFieldValue(`specifications.${spec.relatedSpec.dependentIndex}`, {
					id: formik.values.specifications[spec.relatedSpec.dependentIndex].id,
					isHidden: formik.values.specifications[spec.relatedSpec.dependentIndex].isHidden
				})
			}

			if (!formik.touched.specifications || !formik.touched.specifications[spec.relatedSpec.dependentIndex]) {
				formik.setFieldTouched(`specifications.${spec.relatedSpec.dependentIndex}`, true)
			}
		}
	}

  return (
    <Wrapper isError={specError}>
			<TextInputNew
				id={`text-spec-${spec.index}`}
				name={spec.name}
				title={spec.label}
				hint={spec.hint}
				measure={spec.measure}
				placeholder={spec.placeholder || ''}
				allowedCharacters={spec.allowedCharacters}
				mask={spec.mask}
				value={inputValue}
				isRequired={spec.isRequired}
				onChange={onChange}
			/>

			<ErrorMessage
        name={`specifications.${spec.index}`}
        render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
      />
		</Wrapper>
  )
}
