import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import { TextInput } from 'SRC/ui/FormElements'
import { priceMask } from 'Core/masks'
import { ReduxFormRequired } from 'Core/validators/reduxForm'

class PriceFrom extends React.Component {
  componentDidUpdate () {
    const { priceFromMasked } = this.props
    this.props.change(this.props.formName, 'priceFromUnmasked', priceFromMasked.toString().replace(',', '.'))
  }

  shouldComponentUpdate (nextProps) {
    return nextProps.priceFromMasked !== null && Number(this.props.priceFromMasked) !== Number(nextProps.priceFromMasked)
  }

  render () {
    return [<TextInput
      id='priceFrom'
      label='Cijena od'
      isRequired={this.props.isRequired}
      name='priceFrom'
      measure='€'
      mask={priceMask}
      validate={this.props.isRequired ? ReduxFormRequired : undefined}
    />, <input id='priceFromUnmaksed' type='hidden' name='priceFromUnmaksed' />]
  }
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.formName)
  return {
    priceFromMasked: selector(state, 'priceFrom')
  }
}

PriceFrom.propTypes = {
  priceFromMasked: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  isRequired: PropTypes.bool.isRequired
}

PriceFrom.defaultProps = {
  priceFromMasked: '',
  isRequired: true
}

export default connect(mapStateToProps, {change})(PriceFrom)
