import React from 'react'
import { Textarea, Wrapper } from 'SRC/ui/FormElementsNew'
import { useFormikContext } from 'formik'

export const Description = ({ label }) => {
	const formik = useFormikContext()

	return (
		<Wrapper className='form-group form-group-textarea'>
			<Textarea
				id='description'
				label={label}
				name='description'
				allowedCharacters={['lettersExtended', 'numbers', 'characters']}
				maxLength={1500}
				textContent={formik.values.description}
				onAccept={text => {
					console.log('text', text)
					formik.setFieldValue('description', text)
				}}
			/>
		</Wrapper>
	)
}
