import React from 'react'
import { useFormikContext } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import { rationalNumbers } from 'Core/masks'

import { TextInputNew, Wrapper } from 'SRC/ui/FormElementsNew'

export const PricePercentage = () => {
	const formik = useFormikContext()
  const { t } = useTranslation('common')

	const actualPrice = Number(formik.values.discountedPrice) !== -1
		? Number(formik.values.discountedPrice)
		: Number(formik.values.price) !== -1
			? Number(formik.values.price)
			: -1

	return (
		<Wrapper>
			<TextInputNew
				id='pricePercentage'
				name='discount'
				title={t('Popust')}
				isDisabled={!actualPrice || actualPrice === -1}
				value={formik.values.discount}
				mask={rationalNumbers}
				measure='%'
				onChange={async textValue => {
					if (Number(actualPrice) === -1) {
						await formik.setFieldValue('discountCardPrice', -1, false)
						await formik.setFieldValue('discount', -1, false)
						return
					}

					let percentage = Number(textValue)
					if (percentage && percentage > 99) percentage = 99
			
					const priceWithDiscountCard = percentage !== -1 ? actualPrice - (actualPrice * (percentage / 100)) : actualPrice

					await Promise.all([
						formik.setFieldValue('discount', percentage),
						formik.setFieldValue('discountCardPrice', priceWithDiscountCard)
					])
				}}
			/>
		</Wrapper>
	)
}
