import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useFormikContext, ErrorMessage } from 'formik'
import DatePicker from 'react-datepicker'

import { Wrapper } from 'SRC/ui/FormElementsNew'

const getDate = value => {
  const date = new Date(value)

  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
}

export const DateSpecification = ({ spec }) => {
	const formik = useFormikContext()
	
  const selectedValue = formik.values.specifications && formik.values.specifications[spec.index].value
		? new Date(formik.values.specifications[spec.index].value)
		: null

  return (
    <Wrapper isError={formik.touched.specifications && formik.errors.specifications && formik.errors.specifications[spec.index]}>
			<label>{spec.label}: {spec.isRequired ? <span className='required'>*</span> : null}</label>

			<DatePicker
				showYearDropdown
				disabled={formik.isSubmitting}
				selected={selectedValue}
      	onSelect={value => formik.setFieldValue(`specifications.${spec.index}.value`, getDate(value))}
			/>

			<ErrorMessage
				name={`specifications.${spec.index}`}
				render={msg => <span className='form-group__error-message'>{msg}</span>}
			/>
		</Wrapper>
  )
}
