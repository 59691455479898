import React from 'react'
import { useFormikContext } from 'formik'
import { debounce } from 'throttle-debounce'
import { rationalNumbers } from 'Core/masks'
import useTranslation from 'next-translate/useTranslation'

import { TextInputNew, Wrapper } from 'SRC/ui/FormElementsNew'

import config from 'SRC/config/config.json'

export const PriceTo = () => {
	const formik = useFormikContext()
  const { t } = useTranslation('common')

	const priceToError = formik.errors.priceTo && formik.touched.priceTo
	const priceToErrorMessage = formik.errors.priceTo
	const priceToValue = formik.values.priceTo

	return (
		<Wrapper isError={priceToError}>
			<TextInputNew
				id='priceTo'
				name='priceTo'
				title={t('Cijena do')}
				value={formik.values.priceTo}
				mask={rationalNumbers}
				measure='€'
				onChange={debounce(750, async textValue => {
					console.log('priceTo', textValue)
					const priceFromValue = Number(formik.values.priceFrom)

					let newPriceToValue = textValue

					if (Number(formik.values.priceTypeForAddons) === config.adForm.adPriceTypes.fromTo.id) {
						if (Number(textValue) < priceFromValue && ![0, 1, -1].includes(Number(priceFromValue))) {
							newPriceToValue = formik.values.priceTo
						}
					}

					await formik.setFieldValue('priceTo', newPriceToValue, false)

					if (!formik.touched.priceTo) formik.setFieldTouched('priceTo', true)

					formik.setErrors({
						...formik.errors,
						priceTo: formik.touched.priceTo && [0, -1].includes(Number(textValue)) ? 'Cijena do je obavezno polje' : undefined
					})
				})}
			/>
		</Wrapper>
	)
}

