import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import withTranslation from 'next-translate/withTranslation'
import { connect as formikConnect, FieldArray, ErrorMessage } from 'formik'

import config from 'SRC/config/config.json'

import { SelectDropdown, Wrapper, CheckboxNew } from 'SRC/ui/FormElementsNew'

import { AdsBrandsApi } from 'SRC/modules/ads/brands/api'
import { AdsProductsApi } from 'SRC/modules/ads/products/api'

import {
	getCurrentSubCategory,
	getFlattenCategories
} from 'SRC/modules/categories/selectors'
import { fetchCategories } from 'SRC/modules/categories/actions'

import { AddButton } from '../Props/Swap/AddButton'
import { RemoveButton } from '../Props/Swap/RemoveButton'
import { Header } from '../Header'

class Compatibility extends React.Component {
	constructor(props) {
		super(props)

		this.brandApi = new AdsBrandsApi()
		this.productApi = new AdsProductsApi()

		this.categoryOnlyCount = config.adForm.compatibilities.categoryOnlyCount
		this.newCompatibilityObject = { brand: -1, product: -1, years: [] }

		const years = []
		for (let i = (new Date()).getFullYear(); i >= 1900; i--) years.push(i)

		this.years = years.map(year => ({
			id: `year-${year}`,
			key: `year-${year}`,
			label: year,
			value: `${year}`
		}))

		this.state = this.getInitialState()
	}

	componentDidMount() {
		const { category, adCompatibilities, formik, categories, fetchCategories } = this.props

		if (!Array.isArray(categories) || !categories.length) fetchCategories({})

		const initialCompatibilities = Array.isArray(formik.values.compatibilities) ? formik.values.compatibilities : []

		const { compatibilityCategory } = formik.values

		if (compatibilityCategory) this.loadBrandsToState()

		if (category && initialCompatibilities && initialCompatibilities.length) {
			initialCompatibilities.forEach(compatibility => {
				if (compatibility.brand) {
					this.fetchProducts(compatibility.brand, compatibilityCategory)
				}
			})
		}
	}

	getInitialState = () => ({
		brands: [],
		isBrandsLoading: false,

		products: {},
		isProductsLoading: {}
	})

	/*
	* Categories methods
	*/
	getCategoryField = () => {
		const { formik, i18n: { t } } = this.props

		const categoryOptions = this.prepareCategories()

		const compatibilityCategoryError = formik.touched.compatibilityCategory && formik.errors.compatibilityCategory

		return (
			<Wrapper isError={!!compatibilityCategoryError}>
				<SelectDropdown
					id='compatibilityCategory'
					title={t('common:Kategorija')}
					isRequired
					isDisabled={!Array.isArray(categoryOptions) || !categoryOptions.length}
					isSearchable
					value={formik.values.compatibilityCategory || -1}
					options={categoryOptions}
					onChange={this.onCategoryChange}
				/>

				<ErrorMessage
					name='compatibilityCategory'
					render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
				/>
			</Wrapper>
		)
	}

	prepareCategories = () => {
		const { category } = this.props

		return category && Array.isArray(category.compatibilityCategories) ? category.compatibilityCategories.filter(category => category.parent)
			.map(category => ({
				id: `${category.id}`,
				label: category.title,
				value: `${category.id}`
			}))
			: []
	}

	onCategoryChange = async selectedCategory => {
		const { formik } = this.props

		await formik.setFieldValue('compatibilityCategory', selectedCategory)

		if (!formik.touched.compatibilityCategory) {
			formik.setFieldTouched('compatibilityCategory', true)
		}

		formik.setFieldValue('compatibilities', [{ category: selectedCategory, ...this.newCompatibilityObject }])

		this.loadBrandsToState()
	}

	/*
	* Brands
	*/
	isBrandsRequired = () => {
		const { category } = this.props

		return category && category.compatibilityBrandsQuantity
	}

	loadBrandsToState = async () => {
		const { formik } = this.props
		console.log('loadBrandsToState')

		this.setState({
			isBrandsLoading: true,
			brands: []
		})

		const { compatibilityCategory } = formik.values

		const brandsResponse = await this.brandApi.getBrandsByCategory(Number(compatibilityCategory))

		this.setState({
			isBrandsLoading: false,
			brands: Array.isArray(brandsResponse)
				? brandsResponse.map(brand => ({
					id: `${brand.id}`,
					value: `${brand.id}`,
					label: brand.name,
				}))
				: []
		})
	}

	renderBrand = index => {
		const { formik, i18n: { t } } = this.props
		const { brands, isBrandsLoading } = this.state

		if (!Array.isArray(formik.values.compatibilities) || !formik.values.compatibilities[index]) return null

		const { compatibilityCategory } = formik.values
		const { brand } = formik.values.compatibilities[index]

		let compatibilityBrandError = ''

		if (formik.touched.compatibilities && formik.touched.compatibilities[index] && formik.touched.compatibilities[index].brand) {
			compatibilityBrandError = formik.errors.compatibilities && formik.errors.compatibilities[index]
				? formik.errors.compatibilities[index].brand : ''
		}

		return (
			<Wrapper isError={!!compatibilityBrandError}>
				<SelectDropdown
					id='compatibilityBrand'
					title={t('common:Proizvođač')}
					isDisabled={!Array.isArray(brands) || !brands.length}
					isSearchable
					isRequired={this.isBrandsRequired()}
					value={brand || -1}
					options={brands}
					onChange={async selectedBrand => {
						console.log('selectedBrand', selectedBrand)
						const updatedCompatibility = {
							...formik.values.compatibilities[index],
							brand: selectedBrand,
							product: -1
						}

						await formik.setFieldValue(`compatibilities.${index}`, updatedCompatibility)

						if (!formik.touched.compatibilities || !formik.touched.compatibilities[index] || !formik.touched.compatibilities[index].brand) {
							formik.setFieldTouched(`compatibilities.${index}.brand`, true)
						}

						this.fetchProducts(selectedBrand, compatibilityCategory)
					}}
				/>

				<ErrorMessage
					name={`compatibilities.${index}.brand`}
					render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
				/>
			</Wrapper>
		)

		return null
	}

	/*
	* Products
	*/
	isProductsRequired = () => {
		const { category } = this.props

		return category && category.compatibilityModelsQuantity
	}

	renderModel = index => {
		const { formik, i18n: { t } } = this.props
		const { products, isProductsLoading } = this.state

		if (!Array.isArray(formik.values.compatibilities) || !formik.values.compatibilities[index]) return null

		console.log('products', products)

		const { brand, product } = formik.values.compatibilities[index]

		let options = []

		if (products[brand] && products[brand].length) {
			options = options.concat(products[brand])
		}

		const isDisabled = !brand || Number(brand) === -1 || !Array.isArray(products[brand]) || !products[brand].length

		let compatibilityProductError = ''

		if (formik.touched.compatibilities && formik.touched.compatibilities[index] && formik.touched.compatibilities[index].product) {
			compatibilityProductError = formik.errors.compatibilities && formik.errors.compatibilities[index]
				? formik.errors.compatibilities[index].product : ''
		}

		return (
			<Wrapper isError={!!compatibilityProductError}>
				<SelectDropdown
					id='compatibilityProduct'
					title={t('common:Model')}
					isDisabled={isDisabled}
					isRequired={this.isProductsRequired()}
					isSearchable
					value={product || -1}
					options={options}
					onChange={async selectedProduct => {
						console.log('selectedProduct', selectedProduct)
						await formik.setFieldValue(`compatibilities.${index}.product`, selectedProduct)

						if (!formik.touched.compatibilities || !formik.touched.compatibilities[index] || !formik.touched.compatibilities[index].product) {
							formik.setFieldTouched(`compatibilities.${index}.product`, true)
						}
					}}
				/>

				<ErrorMessage
					name={`compatibilities.${index}.product`}
					render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
				/>
			</Wrapper>
		)
	}

	fetchProducts = async (brand, category) => {
		const { products } = this.state

		if (Number(brand) !== -1) {
			if (!Array.isArray(products[brand])) {
				this.setState(prevState => ({
					isProductsLoading: { ...prevState.isProductsLoading, [brand]: true },
				}))

				const productsResult = await this.productApi.fetchProductByBrandAndCategory({
					category: Number(category),
					brand: Number(brand)
				})

				if (Array.isArray(productsResult)) {
					this.setState(prevState => ({
						products: {
							...prevState.products,
							[brand]: productsResult.map(product => ({
								id: `${product.id}`,
								value: `${product.id}`,
								label: product.model,
							}))
						},
						isProductsLoading: {
							...prevState.isProductsLoading,
							[brand]: false
						}
					}))
				} else {
					this.setState(prevState => ({
						isProductsLoading: {
							...prevState.isProductsLoading,
							[brand]: false
						}
					}))
				}
			}
		}
	}

	/*
	* Years
	*/
	renderYears = index => {
		const { formik, category, i18n: { t } } = this.props

		if (!Array.isArray(formik.values.compatibilities) || !formik.values.compatibilities[index]) return null

		let compatibilityYearsError = ''

		if (formik.touched.compatibilities && formik.touched.compatibilities[index] && formik.touched.compatibilities[index].years) {
			compatibilityYearsError = formik.errors.compatibilities && formik.errors.compatibilities[index]
				? formik.errors.compatibilities[index].years : ''
		}

		return (
			<Wrapper isError={!!compatibilityYearsError}>
				<SelectDropdown
					id='compatibilityYear'
					title={t('common:Godišta')}
					isMultiple
					isSearchable
					isRequired={category && category.compatibilityYears}
					checkedItems={formik.values.compatibilities[index].years}
					options={this.years}
					onChange={selectedYears => {
						console.log('selectedYears', selectedYears)
						formik.setFieldValue(`compatibilities.${index}.years`, selectedYears)

						if (!formik.touched.compatibilities || !formik.touched.compatibilities[index] || !formik.touched.compatibilities[index].years) {
							formik.setFieldTouched(`compatibilities.${index}.years`, true)
						}
					}}
				/>

				<ErrorMessage
					name={`compatibilities.${index}.years`}
					render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
				/>
			</Wrapper>
		)
	}

	renderCompatibility = (index, arrayHelpers) => {
		const { formik, onUniversal } = this.props

		const selectedCategory = this.getSelectedCategory()

		console.log('selectedCategory', selectedCategory)

		return (
			<div className='ads-add__compatibility-row'>
				{/* Remove compatibility */}
				<RemoveButton
					onClick={() => {
						if (formik.values.compatibilities.length === 1) {
							// onUniversal(true)
						}

						arrayHelpers.remove(index)
					}}
					index={index}
				/>

				{/* Brand */}
				{selectedCategory && selectedCategory.requiredAdOptions && ['BrandOnly', 'BrandModel'].includes(selectedCategory.requiredAdOptions)
					? this.renderBrand(index)
					: null
				}

				{/* Product */}
				{selectedCategory && selectedCategory.requiredAdOptions === 'BrandModel' ? this.renderModel(index) : null}

				{/* Years */}
				{selectedCategory && this.shouldRenderYears() ? this.renderYears(index) : null}
			</div>
		)
	}

	getSelectedCategory = () => {
		const { categories, formik } = this.props

		const { compatibilityCategory } = formik.values

		let selectedCategory = null

		if (Number(compatibilityCategory) !== -1) {
			selectedCategory = categories.find(cat => cat.id === Number(compatibilityCategory))
		}

		return selectedCategory
	}

	shouldRenderUniversality = () => {
		const { category } = this.props

		return category && category.compatibilityUniversality && category.compatibilityCount !== this.categoryOnlyCount
	}

	shouldRenderYears = () => {
		const { category } = this.props

		return category && category.compatibilityYears
	}

	renderAddButton = arrayHelpers => {
		const { formik } = this.props

		const { compatibilities, compatibilityCategory } = formik.values

		return Number(compatibilityCategory) !== -1 ? (
			<AddButton
				onClick={() => {
					arrayHelpers.insert(compatibilities.length, { category: compatibilityCategory, ...this.newCompatibilityObject })
				}}
			/>
		) : null
	}

	render() {
		const { formik, category, onUniversal, i18n: { t } } = this.props

		const compatibilityLimit = category.compatibilityCount || 0

		const { compatibilities } = formik.values

		return (
			<div className='ads-add__compatibilities'>
				{/* <Header title='Izaberite za koju kategoriju i proizvođača odgovara predmet koji prodajete' /> */}
				<Header title={t('common:adsAddCompatibilitiesHeader')} />

				<div className='ads-add__compatibility-row'>
					{/* Category */}
					{this.getCategoryField()}

					{/* Universality */}
					{this.shouldRenderUniversality()
						? (
							<div className='dodatna-oprema-checkbox'>
								<CheckboxNew
									id='compatibilityUniversality'
									title={t('common:Ovaj predmet je univerzalan')}
									isChecked={formik.values.compatibilityUniversality}
									onChange={e => {
										const isChecked = e.target.checked

										if (isChecked) formik.setFieldValue('compatibilityUniversality', true)
										else formik.setFieldValue('compatibilityUniversality', false)
									}}
								/>
							</div>
						) : null
					}
				</div>

				<FieldArray
					name='compatibilities'
					render={arrayHelpers => (
						<React.Fragment>
							{compatibilities && compatibilities.length && formik.values.compatibilityUniversality !== true
								? compatibilities.map((_, index) => this.renderCompatibility(index, arrayHelpers))
								: null
							}

							{compatibilities && (compatibilities.length < compatibilityLimit || compatibilityLimit === 0) &&
								formik.values.compatibilityUniversality !== true
								? this.renderAddButton(arrayHelpers)
								: null
							}
						</React.Fragment>
					)}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const flattenCategories = getFlattenCategories(state)

	console.log('flattenCategories', flattenCategories)

	return {
		categories: flattenCategories,
		category: getCurrentSubCategory(state)
	}
}

Compatibility.propTypes = {
	categories: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string
	}).isRequired).isRequired,
	adCompatibilities: PropTypes.arrayOf(PropTypes.shape({
		category: PropTypes.number,
		brand: PropTypes.number,
		product: PropTypes.number,
		years: PropTypes.number
	})),
	category: PropTypes.shape({
		compatibilityCategories: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number,
			parent: PropTypes.bool,
			title: PropTypes.string
		})),
		compatibilityCount: PropTypes.number,
		compatibilityBrandsQuantity: PropTypes.number,
		compatibilityModelsQuantity: PropTypes.number,
		compatibilityYears: PropTypes.number,
		compatibilityUniversality: PropTypes.bool
	}).isRequired,
  fetchCategories: PropTypes.func.isRequired
}

const enhance = compose(
	connect(mapStateToProps, { fetchCategories }),
	formikConnect,
	withTranslation
)

export default enhance(Compatibility)
