export const updateDiscountCardPrice = async (formik, priceValues) => {
	if (!formik || !formik.values) return

	const { price, discountedPrice } = priceValues

	const { discount, discountCardPrice } = formik.values

	const actualPrice = Number(discountedPrice) !== -1
		? Number(discountedPrice)
		: Number(price) !== -1
			? Number(price)
			: -1

	console.log('actualPrice', actualPrice)

	if (Number(actualPrice) === -1) {
		await formik.setFieldValue('discountCardPrice', -1, false)
		await formik.setFieldValue('discount', -1, false)
		return
	}

	const percentage = Number(discount)
	if (!percentage || Number(percentage) === -1) return

	const priceWithDiscountCard = actualPrice - (actualPrice * (percentage / 100))

	await formik.setFieldValue('discountCardPrice', priceWithDiscountCard)
}