import React from 'react'
import { connect } from 'react-redux'
import { getEquipment } from 'SRC/modules/ads/equipment/selectors'
import { groups as equipmentGroups } from 'SRC/modules/ads/equipment/data'
import PropTypes from 'prop-types'
import { Group } from '../Group'

export class List extends React.PureComponent {
  groupEquipments = () => {
    this.props.items.sort((a, b) => {
      if (a.weight !== b.weight) {
        return a.weight - b.weight
      } else {
        return a.title.localeCompare(b.title)
      }
    })
    return equipmentGroups.map(group => ({
      title: group.title,
      id: group.id,
      items: this.props.items.filter(item => item.grupa === group.code)
    }))
  }

  render () {
    return this.groupEquipments().map(group =>
      group.items.length ? <Group {...group} key={group.id} /> : null
    )
  }
}

const mapStateToProps = state => ({
  items: getEquipment(state).map(item => ({...item, label: item.title}))
})

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    priority: PropTypes.number,
    grupa: PropTypes.string.isRequired
  })).isRequired
}

List.defaultProps = {
  items: []
}

List.displayName = 'Equipment'

export default connect(mapStateToProps)(List)
