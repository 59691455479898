import { BaseApi } from 'Core/api/BaseApi'
import { TypesHelper } from 'SRC/utils'

export class Api extends BaseApi {
  getRegionsByCountry = async countryId => {
    const defaultResult = []
    if (TypesHelper.isPositiveNumber(countryId)) {
      try {
        const {body} = await Api.post(`/region/listByCountry`, {country: countryId})
        if (!body.error && body.regions && Array.isArray(body.regions)) {
          return body.regions.map(region => ({
            id: region.id,
            label: region.name,
            value: region.id
          }))
        } else {
          return defaultResult
        }
      } catch (error) {
        return defaultResult
      }
    } else {
      return defaultResult
    }
  }
}
