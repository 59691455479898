import React from 'react'
import PropTypes from 'prop-types'
import { TextInput } from 'SRC/ui/FormElements'
import { priceMask } from 'Core/masks'

export const DiscountCardPrice = ({ onChange }) => <TextInput
  id='discountCardPrice'
  label='Cijena uz popust karticu'
  name='discountCardPrice'
  onAccept={onChange}
  measure='€'
  mask={priceMask}
/>

DiscountCardPrice.propTypes = {
  onChange: PropTypes.func.isRequired
}
