import React from 'react'
import { useFormikContext } from 'formik'
import { debounce } from 'throttle-debounce'
import { rationalNumbers } from 'Core/masks'
import useTranslation from 'next-translate/useTranslation'

import { TextInputNew, Wrapper } from 'SRC/ui/FormElementsNew'

import config from 'SRC/config/config.json'

export const PriceFrom = () => {
	const formik = useFormikContext()
  const { t } = useTranslation('common')

	const priceFromError = formik.errors.priceFrom && formik.touched.priceFrom
	const priceFromErrorMessage = formik.errors.priceFrom
	const priceFromValue = formik.values.priceFrom

	return (
		<Wrapper isError={priceFromError}>
			<TextInputNew
				id='priceFrom'
				name='priceFrom'
				title={t('Cijena od')}
				value={formik.values.priceFrom}
				mask={rationalNumbers}
				measure='€'
				onChange={debounce(750, async textValue => {
					const priceToValue = Number(formik.values.priceTo)

					let newPriceFromValue = textValue

					if (Number(formik.values.priceTypeForAddons) === config.adForm.adPriceTypes.fromTo.id) {
						if (Number(textValue) > priceToValue && ![0, 1, -1].includes(priceToValue)) {
							newPriceFromValue = formik.values.priceFrom
						}
					}

					await formik.setFieldValue('priceFrom', newPriceFromValue, false)

					if (!formik.touched.priceFrom) formik.setFieldTouched('priceFrom', true)

					formik.setErrors({
						...formik.errors,
						priceFrom: formik.touched.priceFrom && [0, -1].includes(Number(textValue)) ? 'Cijena od je obavezno polje' : undefined
					})
				})}
			/>
		</Wrapper>
	)
}

