import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { connect as formikConnect } from 'formik'
import withTranslation from 'next-translate/withTranslation'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getPriceTypes } from 'SRC/modules/users/Profile/selectors'
import config from 'SRC/config/config.json'
import { CheckboxNew } from 'SRC/ui/FormElementsNew'
import { PriceOnRequest } from '../PriceOnRequest'
import { PriceTypeForAddons } from '../PriceTypeForAddons'
import { RegularPrice } from '../RegularPrice'
import { DiscountPrice } from '../DiscountPrice'
import { PriceFrom } from '../PriceFrom'
import { PriceTo } from '../PriceTo'
import { PriceType } from '../PriceType'
import { AuctionPrice } from '../AuctionPrice'
import { PricePercentage } from '../PricePercentage'
import { DiscountCardPrice } from '../DiscountCardPrice'

class Price extends React.Component {
	constructor(props) {
		super(props)

		const { formik } = props

		const { priceOnRequest } = formik.values

		const showPriceOnRequest = this.getShowPriceOnRequest()
    const showAuctionPriceOnRequest = this.getShowAuctionPriceOnRequest()

		if (!showPriceOnRequest && !showAuctionPriceOnRequest && priceOnRequest) {
			formik.setFieldValue('priceOnRequest', false)
		}
	}

	getShowPriceOnRequest = () => {
    const { hasPriceOnRequestAddon, category } = this.props

		if (category && category.requiredAdOptions === 'Services') {
      return true
    }

    if (category && category.allowPriceOnRequest) {
      return hasPriceOnRequestAddon
    }

    return false
  }

  getShowAuctionPriceOnRequest = () => {
    const { showAuctionPrice, auctionPriceType } = this.props

    if (showAuctionPrice && auctionPriceType && auctionPriceType.hasPriceOnRequest) {
      return true
    }

    return false
  }

	getShowFromToPrice = () => {
		const { formik, hasPriceFromToAddon } = this.props
		const { priceTypeForAddons, priceOnRequest } = formik.values

		return priceTypeForAddons && !priceOnRequest &&
			Number(priceTypeForAddons) === config.adForm.adPriceTypes.fromTo.id && hasPriceFromToAddon
	}

	getPriceTypeForAddonsOptions = () => {
		const {
			hasPriceFromToAddon,
			hasPriceRangeSeparateAddon,
			hasPartnerAddon,
			hasPriceFromAddon,
			priceTypes,
			i18n: { t }
		} = this.props
		
		const { adPriceTypes } = config.adForm
		const options = [
			{
				id: `${adPriceTypes.retail.id}`,
				label: t(`common:${adPriceTypes.retail.title}`),
				value: `${adPriceTypes.retail.id}`
			}
		]

    if (hasPartnerAddon) {
      options.push({
        id: `${adPriceTypes.discountCard.id}`,
        label: t(`common:${adPriceTypes.discountCard.title}`),
        value: `${adPriceTypes.discountCard.id}`
      })
    }

		// od - do
    if (hasPriceFromToAddon || hasPriceRangeSeparateAddon) {
      options.push({
        id: `${adPriceTypes.fromTo.id}`,
        label: t(`common:${adPriceTypes.fromTo.title}`),
        value: `${adPriceTypes.fromTo.id}`
      })
    }

		// vec od
    if (hasPriceFromAddon) {
      options.push({
        id: `${adPriceTypes.from.id}`,
        label: t(`common:${adPriceTypes.from.title}`),
        value: `${adPriceTypes.from.id}`
      })
    }

    if (Array.isArray(priceTypes) && priceTypes.length) {
      priceTypes.forEach(pt => {
        options.push({
          id: pt.id,
          label: pt.name,
          value: pt.name
        })
      })
    }

		return options
	}

  renderPriceTypeForAddons = () => {
    const {
			formik,
      hasPartnerAddon,
      hasPriceFromToAddon,
      hasPriceFromAddon,
      hasPriceRangeSeparateAddon,
      priceTypes,
			auctionPriceType
    } = this.props

		const { priceOnRequest } = formik.values

    if (priceOnRequest) return null

    const hasAddons = hasPartnerAddon || hasPriceFromToAddon || hasPriceRangeSeparateAddon || hasPriceFromAddon
    const hasPriceTypes = Array.isArray(priceTypes) && priceTypes.length

    if (hasAddons || hasPriceTypes) {
      return (
        <PriceTypeForAddons
					auctionPriceType={auctionPriceType}
          options={this.getPriceTypeForAddonsOptions()}
        />
      )
    }

    return null
  }

	renderRegularPrice = () => {
		const { formType, formik } = this.props
		const { adPriceTypes } = config.adForm

		const { priceOnRequest, priceTypeForAddons } = formik.values

    if (priceOnRequest) return null

    if ((!priceTypeForAddons || ![`${adPriceTypes.fromTo.id}`, `${adPriceTypes.from.id}`, 'Aukcija'].includes(priceTypeForAddons))) {
      return (
        <React.Fragment>
          <RegularPrice />

          {formType !== 'addBasic' ? <DiscountPrice /> : null}
        </React.Fragment>
      )
    }

    return null
	}

  renderAuctionPrice = () => {
    const { showAuctionPrice, auctionPriceType, formik, i18n: { t } } = this.props

		console.log('renderAuctionPrice showAuctionPrice', showAuctionPrice)
		console.log('renderAuctionPrice auctionPriceType', auctionPriceType)

		const { priceOnRequest, priceTypeForAddons } = formik.values

		if (!showAuctionPrice || priceTypeForAddons !== 'Aukcija' || !auctionPriceType) return null

		return (
			<React.Fragment>
				{!priceOnRequest ? <AuctionPrice priceType={auctionPriceType} /> : null}

				{auctionPriceType.hasPriceOnRequest ? (
					<div className='dodatna-oprema-checkbox'>
						<CheckboxNew
							id='auctionPriceOnRequest'
							title={auctionPriceType.priceOnRequestName}
							isChecked={formik.values.priceOnRequest}
							onChange={e => { 
								formik.setFieldValue('priceOnRequest', e.target.checked)
							}}
							text={t('common:Cijena na upit')}
						/>
					</div>
				) : null}
			</React.Fragment>
		)
  }

	renderRangePrice = () => {
    const { formik, hasPriceFromToAddon, hasPriceRangeSeparateAddon, hasPriceFromAddon } = this.props
		const { adPriceTypes } = config.adForm

		const { priceOnRequest, priceTypeForAddons } = formik.values

    if (priceOnRequest) return null

		const showFromToPrice = Number(priceTypeForAddons) === adPriceTypes.fromTo.id && (hasPriceFromToAddon || hasPriceRangeSeparateAddon)
  	const showFromPriceOnly = Number(priceTypeForAddons) === adPriceTypes.from.id && hasPriceFromAddon

		if (showFromToPrice) {
			return (
				<React.Fragment>
					<PriceFrom />
					<PriceTo />
				</React.Fragment>
			)
		}

		if (showFromPriceOnly) {
			return (
				<PriceFrom />
			)
		}

    return null
	}

	renderDiscountCardPrice = () => {
    const { formik, hasPartnerAddon } = this.props

		const { adPriceTypes } = config.adForm
		const { priceOnRequest, priceTypeForAddons } = formik.values

		const showDiscountCardPrice = Number(priceTypeForAddons) === adPriceTypes.discountCard.id && hasPartnerAddon

    if (priceOnRequest || !showDiscountCardPrice) return null

		return (
			<React.Fragment>
				<PricePercentage />
				<DiscountCardPrice />
			</React.Fragment>
		)
	}

	render() {
		const { formik, formType } = this.props

		const showFromToPrice = this.getShowFromToPrice()

		const { priceOnRequest, priceTypeForAddons } = formik.values

		return (
      <React.Fragment>
        {this.getShowPriceOnRequest() ? <PriceOnRequest /> : null}
				{this.renderPriceTypeForAddons()}
				{this.renderRegularPrice()}
				{this.renderAuctionPrice()}
				{this.renderRangePrice()}
				{this.renderDiscountCardPrice()}

        {!priceOnRequest && priceTypeForAddons !== 'Aukcija' ? <PriceType /> : null}
      </React.Fragment>
				
		)
	}
}

const mapStateToProps = (state) => {
  const priceTypes = getPriceTypes(state)

  const auctionPriceType = Array.isArray(priceTypes) ? priceTypes.find(pt => pt.name === 'Aukcija') : null

	return {
		category: getCurrentSubCategory(state),
    priceTypes,
    showAuctionPrice: Boolean(auctionPriceType),
    auctionPriceType
	}
}

const enhance = compose(
	connect(mapStateToProps),
	formikConnect,
	withTranslation
)

export default enhance(Price)
