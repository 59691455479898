import React from 'react'
import { Textarea } from 'SRC/ui/FormElements'
// import { ReduxFormRequired } from 'Core/validators/reduxForm'

export const Description = () =>
  <Textarea
    id='description'
    label='Kraći opis'
    name='description'
    allowedCharacters={['letters', 'numbers', 'characters']}
    maxLength={1500}
    wrapperClass='form-group form-group-textarea'
  />
