import React from 'react'
import { Header } from '../Header'
import { Select, Hidden } from 'SRC/ui/FormElements'
import { getCountries } from 'SRC/modules/geoObjects/countries/selectors'
import { getCitiesByCountryWithinRegions } from 'SRC/modules/geoObjects/cities/selectors'
import { getLocations } from 'SRC/modules/geoObjects/locations/selectors'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CitiesApi } from 'SRC/modules/geoObjects/cities/api'
import { LocationsApi } from 'SRC/modules/geoObjects/locations/api'
import { change } from 'redux-form'
import { ReduxFormRequired } from 'Core/validators/reduxForm'

export class Location extends React.PureComponent {
  constructor (props) {
    super(props)

    const { initialCountry, citiesByCountryWithinRegions } = props

    let cities = []

    if (citiesByCountryWithinRegions && Array.isArray(citiesByCountryWithinRegions[initialCountry])) {
      cities = citiesByCountryWithinRegions[initialCountry]
    }

    this.state = {
      isCitiesLoading: false,
      cities: this.prepareCities(cities),
      isLocationsLoading: false,
      locations: this.prepareLocations(this.props.locations)
    }
  }

  getCountryField = () => ({
    id: 'country',
    name: 'country',
    label: 'Država',
    options: this.prepareCountries(),
    onChange: this.onCountryChange,
    isRequired: true,
    selectClassName: 'ads-add__select',
    validate: ReduxFormRequired
  })

  prepareCountries = () => this.props.countries.filter(country => !country.parent).map(country => {
    const isGrouped = Boolean(this.props.countries.filter(item => item.parent === country.id).length)
    return {
      id: country.id,
      label: country.label,
      isGrouped,
      isDisabled: false,
      value: country.id,
      options: isGrouped ? this.props.countries.filter(item => item.parent === country.id).map(item => ({
        value: item.id,
        isDisabled: false,
        label: item.label
      })) : null
    }
  })

  onCountryChange = async (e) => {
    const country = Number(e.target.value)
    await this.props.change(this.props.formName, this.getRegionField().name, '')
    await this.props.change(this.props.formName, this.getCityField().name, '')
    await this.props.change(this.props.formName, this.getLocationField().name, '')
    await this.loadCitiesToState(country)
  }

  loadCitiesToState = async country => {
    await this.setState({
      isCitiesLoading: true,
      locations: [],
      isLocationsLoading: true
    })

    const citiesApi = new CitiesApi()
    const citiesWithinRegions = await citiesApi.getCitiesByCountryWithinRegions(country)
    const preparedCities = this.prepareCities(citiesWithinRegions)
    await this.setState({ cities: preparedCities, isCitiesLoading: false })
  }

  prepareCities = (citiesWithinRegions = []) => citiesWithinRegions.map(region => ({
    id: region.id,
    label: region.label,
    value: `region-${region.id}`,
    isGrouped: true,
    options: region.cities.map(city => ({
      id: city.id,
      label: city.label,
      value: city.value,
      region: region.id
    }))
  }))

  getCityField = () => ({
    id: 'city',
    name: 'city',
    label: 'Grad',
    options: this.state.cities,
    onChange: this.onCityChange,
    disabled: this.state.isCitiesLoading,
    isRequired: true,
    selectClassName: 'ads-add__select',
    validate: ReduxFormRequired
  })

  getRegionField = () => ({id: 'region', name: 'region'})

  onCityChange = async e => {
    const city = Number(e.target.value)
    this.saveRegion(city)
    await this.props.change(this.props.formName, this.getLocationField().name, '')
    await this.loadLocationsToState(city)
  }

  saveRegion = city => {
    const arCity = this.state.cities.filter(item => item.options.some(option => option.id === city))
    const selectedRegion = arCity.length ? `${arCity[0].id}` : null
    this.props.change(this.props.formName, this.getRegionField().name, selectedRegion)
  }

  getLocationField = () => ({
    id: 'locations',
    name: 'location',
    label: 'Lokacija',
    options: this.state.locations,
    disabled: this.state.isLocationsLoading,
    isRequired: true,
    selectClassName: 'ads-add__select',
    validate: ReduxFormRequired
  })

  loadLocationsToState = async city => {
    await this.setState({...this.state, isLocationsLoading: true})
    const api = new LocationsApi()
    const locations = await api.getLocationsByCity(Number(city))
    this.setState({
      ...this.state,
      locations: this.prepareLocations(locations),
      isLocationsLoading: !locations.length
    })
  }

  prepareLocations = (locations = []) => {
    const sortedLocations = locations.sort((a, b) => {
      if (a.priority === b.priority) return 0
      else if (a.priority === null) return 1
      else if (b.priority === null) return -1
      else return a.priority - b.priority
    })
    return sortedLocations.map(({id, label, value}) => ({id, label, value}))
  }

  render () {
    const countryField = this.getCountryField()
    const cityField = this.getCityField()
    const locationField = this.getLocationField()
    return (
      <div className='ads-add__location'>
        <Header title='Lokacija oglasa' />
        <div style={{ width: '100%', display: 'flex' }}>
          <Select {...countryField} />
          <Hidden name={this.getRegionField().name} />
          <Select {...cityField} />
          <Select {...locationField} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  countries: getCountries(state),
  citiesByCountryWithinRegions: getCitiesByCountryWithinRegions(state),
  locations: getLocations(state)
})

Location.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    parent: PropTypes.number,
    id: PropTypes.number.isRequired
  })).isRequired,
  initialCountry: PropTypes.number,
  change: PropTypes.func.isRequired,
  citiesByCountryWithinRegions: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired
  })),
  formName: PropTypes.oneOf(['addAd', 'editAd'])
}

Location.defaultProps = {
  countries: [],
  citiesByCountryWithinRegions: {},
  locations: []
}

export default connect(mapStateToProps, {change})(Location)
