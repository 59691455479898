import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'SRC/ui/FormElements'
import { ReduxFormRequired } from 'Core/validators/reduxForm'

export const PriceTypeForAddons = ({ onChange, options }) =>
  <Select
    id='priceTypeForAddons'
    label='Vrsta cijene'
    isRequired
    showLabelDefault={false}
    name='priceTypeForAddons'
    options={options}
    onChange={onChange}
    validate={ReduxFormRequired}
  />

PriceTypeForAddons.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
}
