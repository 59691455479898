import React from 'react'
import { TextInput } from 'SRC/ui/FormElements'
import { priceMask } from 'Core/masks'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'

const validateDiscountPrice = (value, allValues) => {
  const preparedValue = Number(allValues.discountedPriceUnmasked)
  const preparedPriceValue = Number(allValues.priceUnmasked)
  let isValid
  if (isNaN(preparedPriceValue)) {
    isValid = isNaN(preparedValue)
  } else {
    if (isNaN(preparedValue)) {
      isValid = true
    } else {
      isValid = preparedValue < preparedPriceValue
    }
  }
  return isValid ? undefined : 'Error'
}

class DiscountPrice extends React.Component {
  componentDidUpdate () {
    const unmasked = this.props.discountPriceMasked === '' ? 0 : this.props.discountPriceMasked.replace(',', '.')
    this.props.change(this.props.formName, 'discountedPriceUnmasked', unmasked)
  }

  shouldComponentUpdate (nextProps) {
    return nextProps.discountPriceMasked !== undefined && this.props.discountPriceMasked !== nextProps.discountPriceMasked
  }
  render () {
    return [<TextInput
      id='discountPrice'
      label='Cijena sa popustom'
      name='discountedPrice'
      measure='€'
      mask={priceMask}
      validate={validateDiscountPrice}
    />, <input id='discountedPriceUnmasked' type='hidden' name='discountedPriceUnmasked' />]
  }
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.formName)
  return {
    discountPriceMasked: selector(state, 'discountedPrice')
  }
}

DiscountPrice.propTypes = {
  discountPriceMasked: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired
}

DiscountPrice.defaultProps = {
  discountPriceMasked: ''
}

export default connect(mapStateToProps, {change})(DiscountPrice)
