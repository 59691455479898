import PropTypes from 'prop-types'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'

export const AddButton = ({onClick}) => {
  const { t } = useTranslation('common')

  return (
    <a className='dodaj-model-btn' href='javascript:void (0)' onClick={onClick}>
      <span className='add-model'>[+]</span> {t('Dodaj još modela')}
    </a>
  )
}

AddButton.propTypes = {
  onClick: PropTypes.func
}
