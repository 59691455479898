import { ServiceTypesApi } from '../../api'
import { setServiceTypesLoading } from '../setServiceTypesLoading'
import { setServiceTypes } from '../setServiceTypes'
import { getSortOptions } from 'SRC/modules/ads/filter/functions'

export const fetchServiceTypes = options => async dispatch => {
  dispatch(setServiceTypesLoading(true))

  if (!options.serviceType) dispatch(setServiceTypes([]))

  const { service } = options

  const api = new ServiceTypesApi()
  const serviceTypes = await api.fetchServiceTypesByService(Number(service))

  dispatch(setServiceTypes(serviceTypes))
  dispatch(setServiceTypesLoading(false))
}
