import PropTypes from 'prop-types'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useFormikContext } from 'formik'
import { adFiltersLabels } from 'src/core/constants'
import { SelectDropdown, Wrapper } from 'SRC/ui/FormElementsNew'

const Condition = () => {
  const formik = useFormikContext()
  const { t } = useTranslation('common')

  const getConditionField = () => {
    return {
      id: 'condition',
      title: t('Stanje'),
      isRequired: true,
      isOptional: false,
      options: [
        {
          id: 'condition-new',
          label: t('Novo'),
          value: 'new'
        },
        {
          id: 'condition-used',
          label: t('Polovno'),
          value: 'used'
        }
      ],
      onChange: onConditionChange,
      value: formik.values.condition
    }
  }

  const onConditionChange = value => {
    formik.setFieldValue('condition', value)
  }

  return (
    <Wrapper>
      <SelectDropdown {...getConditionField()} />
    </Wrapper>
  )
}

Condition.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }).isRequired
}

export default Condition
