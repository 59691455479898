import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'

const Cancel = ({ onClick }) => <ReactSVG src='/icons/cancel.svg' onClick={onClick}
  beforeInjection={svg => {
    svg.setAttribute('style', 'cursor: pointer;')
  }} />

export const RemoveBtn = ({ onClick }) => <div className='ads-add__photos-list-view-item-remove-btn'>
  <Cancel onClick={onClick} />
</div>

RemoveBtn.propTypes = {
  onClick: PropTypes.func.isRequired
}

Cancel.propTypes = {
  onClick: PropTypes.func.isRequired
}
