import React from 'react'

import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { capitalize } from 'SRC/utils/Utils'
import useTranslation from 'next-translate/useTranslation'

import { CheckboxNew } from 'SRC/ui/FormElementsNew'

import { Header } from '../../Header'

const showSelectedEquipment = checkedEquipment => {
	if (!checkedEquipment || !checkedEquipment.length) return ''

	const str = checkedEquipment.map(item => item.title).join(', ')
	
	if (str.length > 30) {
		return `${str.substr(0, 27)}...`
	}

	return str
}

export const Group = ({ groupId, items, title }) => {
	const formik = useFormikContext()
  const { t } = useTranslation('common')

  if (!formik || !formik.values || !formik.values.equipment) return null

	const checkedEquipment = formik.values.equipment && formik.values.equipment[groupId] && formik.values.equipment[groupId].length
		? items.filter(eqItem => formik.values.equipment[groupId].includes(`${eqItem.id}`)).map(eqItem => `${eqItem.id}`)
		: []

  return (
    <React.Fragment>
      <Header title={t(title)} key={`header-${groupId}`} />

      {items.map(item => (
        <div className='dodatna-oprema-checkbox' key={item.id}>
          <CheckboxNew
            id={`${item.id}`}
            title={item.title}
            isChecked={checkedEquipment.includes(`${item.id}`)}
            onChange={e => {
              const isChecked = e.target.checked
              console.log('isChecked', isChecked)
              if (isChecked) formik.setFieldValue(`equipment.${groupId}`, [...formik.values.equipment[groupId], `${item.id}`])
              else formik.setFieldValue(`equipment.${groupId}`, formik.values.equipment[groupId].filter(eqId => eqId !== `${item.id}`))
            }}
          />
        </div>
      ))}
    </React.Fragment>
  )
}

Group.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any.isRequired
  })).isRequired,
  title: PropTypes.string.isRequired,
  groupId: PropTypes.number.isRequired
}

Group.defaultProps = {
  items: []
}

Group.displayName = 'EquipmentGroup'
