import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import withTranslation from 'next-translate/withTranslation'
import { connect as formikConnect, FieldArray, swap } from 'formik'

import config from 'SRC/config/config.json'

import { SelectDropdown, Wrapper, CheckboxNew } from 'SRC/ui/FormElementsNew'

import { AdsBrandsApi } from 'SRC/modules/ads/brands/api'
import { AdsProductsApi } from 'SRC/modules/ads/products/api'

import {
	getCurrentSubCategory,
	getCategoriesWithSwappPresence
} from 'SRC/modules/categories/selectors'
import { fetchCategories } from 'SRC/modules/categories/actions'

import { AddButton } from '../AddButton'
import { RemoveButton } from '../RemoveButton'
import { Header } from '../../Header'

class Swapps extends React.Component {
	brandApi = new AdsBrandsApi()
	productApi = new AdsProductsApi()
	maxSwapCount = 3

	state = {
		brands: {},
		brandsLoading: {},
		products: {},
		productsLoading: {},
		selectedCategory: Array(3).fill(null)
	}

	componentDidMount() {
		const { formik, categories, fetchCategories } = this.props

		const { swapps } = formik.values

		if (!Array.isArray(categories) || !categories.length) fetchCategories({})

		if (Array.isArray(swapps) && swapps.length) {
			const selectedCategoryState = []

			swapps.forEach((swapp, index) => {
				if (Number(swapp.category) !== -1) {
					const category = Array.isArray(categories) ? categories.find(cat => cat.id === Number(swapp.category)) : null

					if (category && ['BrandModel', 'BrandOnly'].includes(category.requiredAdOptions)) {
						this.fetchCategoryBrands(Number(swapp.category))

						if (category.requiredAdOptions === 'BrandModel' && Number(swapp.brand) !== -1) {
							this.fetchProducts(Number(swapp.brand), Number(swapp.category))
					  }

						selectedCategoryState[index] = {
							category: category.id,
							requiredAdOptions: category.requiredAdOptions,
							swappSpecification1: category.swappSpecification1,
							swappSpecification2: category.swappSpecification2
						}
					}
				}
			})

			// SET selected category to the state
			if (selectedCategoryState.length) this.setState({ selectedCategory: selectedCategoryState })
		}
	}

	fetchCategoryBrands = async categoryId => {
		const { categories } = this.props
		const { brands } = this.state

		if (Number(categoryId) !== -1) {
			const category = Array.isArray(categories) ? categories.find(cat => cat.id === Number(categoryId)) : null

			if (category && ['BrandModel', 'BrandOnly'].includes(category.requiredAdOptions)) {
				if (!brands[categoryId]) {
					this.setState(prevState => ({
						brandsLoading: { ...prevState.brandsLoading, [categoryId]: true },
					}))
	
					const brandsResult = await this.brandApi.getBrandsByCategory(Number(categoryId))
	
					if (Array.isArray(brandsResult)) {
						this.setState(prevState => ({
							brands: {
								...prevState.brands,
								[categoryId]: brandsResult
							},
							brandsLoading: {
								...prevState.brandsLoading,
								[categoryId]: false
							}
						}))
					} else {
						this.setState(prevState => ({
							brandsLoading: {
								...prevState.brandsLoading,
								[categoryId]: false
							}
						}))
					}
				}
			}
		}
	}

	fetchProducts = async (brand, category) => {
		const { products } = this.state

		if (brand !== -1) {
				if (!products[brand]) {
					this.setState(prevState => ({
						productsLoading: { ...prevState.productsLoading, [brand]: true },
					}))
	
					const productsResult = await this.productApi.fetchProductByBrandAndCategory({
						brand: Number(brand),
						category: Number(category)
					})
	
					if (Array.isArray(productsResult)) {
						this.setState(prevState => ({
							products: {
								...prevState.products,
								[brand]: productsResult
							},
							productsLoading: {
								...prevState.productsLoading,
								[brand]: false
							}
						}))
					} else {
						this.setState(prevState => ({
							productsLoading: {
								...prevState.productsLoading,
								[brand]: false
							}
						}))
					}
				}
			}
	}
	
	renderSwappType = () => {
		const { formik, i18n: { t } } = this.props
		const { swapOptions } = config.adForm

		const options = swapOptions.map(option => ({
			id: `${option.id}`,
			label: t(`common:${option.title}`),
			value: option.value
		}))

		const swappError = formik.touched.swapp && formik.errors.swapp

		return (
			<Wrapper isError={swappError}>
				<SelectDropdown
					id='swapp'
					title={t('common:Zamjena')}
					isSearchable={false}
					isOptional={false}
					value={formik.values.swapp}
					options={options}
					onChange={async selectedSwappType => {
						console.log('selectedSwappType', selectedSwappType)
						await formik.setFieldValue('swapp', selectedSwappType)
						if (!formik.touched.swapp) formik.setFieldTouched('swapp', true)

						if (selectedSwappType === 'No') {
							await formik.setFieldValue('swapps', -1)
							this.setState({ selectedCategory: Array(3).fill(null) })
						}

						if (selectedSwappType !== 'No' && formik.values.swapp === 'No') {
							await formik.setFieldValue('swapps', [{
								category: -1,
								brand: -1,
								product: -1,
								specification1: -1,
								specificationValue1: -1,
								specification2: -1,
								specificationValue2: -1
							}])
						}
					}}
				/>
			</Wrapper>
		)
	}

	renderSwapp = (index, arrayHelpers) => {
		const { categories, formik, i18n: { t } } = this.props
		const { selectedCategory } = this.state

		const options = Array.isArray(categories) ? categories.map(category => ({
			id: `${category.id}`,
			value: JSON.stringify({
				category: category.id,
				requiredAdOptions: category.requiredAdOptions,
				swappSpecification1: category.swappSpecification1,
				swappSpecification2: category.swappSpecification2
			}),
			label: category.title
		})) : []

		const swapp = formik.values.swapps[index]

		let swappCategoryError = ''

		if (Array.isArray(formik.touched.swapps) && formik.touched.swapps[index]) {
			swappCategoryError = Array.isArray(formik.errors.swapps) && formik.errors.swapps[index]
				? formik.errors.swapps[index].category : ''
		}

		return (
			<div className={`ads-add__swap-row${index === 0 ? ' ads-add__swap-row_first' : ''}`}>
				<RemoveButton
					key={`remove-swapps-${index}-${Math.ceil(Math.random() * 1000)}`}
					index={index}
					onClick={() => {
						arrayHelpers.remove(index)
						const newSelectedCategoryState = [...selectedCategory]
						
						for (let i = index; i < newSelectedCategoryState.length; i++) {
							newSelectedCategoryState[i] = newSelectedCategoryState[i + 1] || null
						}

						this.setState({ selectedCategory: newSelectedCategoryState })
					}}
				/>

				<Wrapper isError={swappCategoryError} key={`swappCategory_${index}-${Math.ceil(Math.random() * 1000)}`}>
					<SelectDropdown
						id={`swappCategory_${index}`}
						title={t('common:Kategorija')}
						isSearchable
						value={selectedCategory && selectedCategory[index] ? JSON.stringify(selectedCategory[index]) : -1}
						options={options}
						onChange={async selectedCat => {
							let parsedValue = -1

							try {
								parsedValue = JSON.parse(selectedCat)

								console.log('parsedValue', parsedValue)

								const categoryValue = Number(parsedValue) !== -1 && Number(parsedValue.category) !== -1
									? Number(parsedValue.category)
									: -1

								await formik.setFieldValue(`swapps.${index}.category`, categoryValue)

								// Update category in the state
								const newSelectedCategoryState = [...selectedCategory]
								newSelectedCategoryState[index] = Number(categoryValue) !== -1 ? parsedValue : null
								this.setState({ selectedCategory: newSelectedCategoryState })

								if (!formik.touched.swapps || !formik.touched.swapps[index]) {
									formik.setFieldTouched(`swapps.${index}`, true)
								}
								
								// Clear Brand and product
								formik.setFieldValue(`swapps.${index}.brand`, -1)
								formik.setFieldValue(`swapps.${index}.product`, -1)
								
								// Clear Specification 1
								formik.setFieldValue(`swapps.${index}.specification1`, -1)
								formik.setFieldValue(`swapps.${index}.specificationValue1`, -1)

								// Clear Specification 2
								formik.setFieldValue(`swapps.${index}.specification2`, -1)
								formik.setFieldValue(`swapps.${index}.specificationValue2`, -1)

								if (categoryValue !== -1) {
									this.fetchCategoryBrands(categoryValue)
								}
							} catch (err) {
								// Clear Category
								await formik.setFieldValue(`swapps.${index}.category`, -1)
								this.setState({ selectedCategory: Array(3).fill(null) })

								// Clear Brand and product
								formik.setFieldValue(`swapps.${index}.brand`, -1)
								formik.setFieldValue(`swapps.${index}.product`, -1)
								
								// Clear Specification 1
								formik.setFieldValue(`swapps.${index}.specification1`, -1)
								formik.setFieldValue(`swapps.${index}.specificationValue1`, -1)

								// Clear Specification 2
								formik.setFieldValue(`swapps.${index}.specification2`, -1)
								formik.setFieldValue(`swapps.${index}.specificationValue2`, -1)
							}
						}}
					/>
				</Wrapper>

				{/* Brand */}
				{selectedCategory && selectedCategory[index] && ['BrandOnly', 'BrandModel'].includes(selectedCategory[index].requiredAdOptions)
					? this.renderBrand(index)
					: null}

				{/* Model */}
				{selectedCategory && selectedCategory[index] && selectedCategory[index].requiredAdOptions === 'BrandModel'
					? this.renderModel(index)
					: null}

				{/* Specification 1 */}
				{selectedCategory && selectedCategory[index] && selectedCategory[index].swappSpecification1
					? this.renderSpecification({
						index,
						specification: selectedCategory[index].swappSpecification1,
						number: 1
					}) : null}

				{/* Specification 2 */}
				{selectedCategory && selectedCategory[index] && selectedCategory[index].swappSpecification2
					? this.renderSpecification({
						index,
						specification: selectedCategory[index].swappSpecification2,
						number: 2
					}) : null}
			</div>
		)
	}

	renderBrand = index => {
		const { formik, i18n: { t } } = this.props
		const { brands, brandsLoading } = this.state

		if (!Array.isArray(formik.values.swapps) || !formik.values.swapps[index]) return null

		const { category } = formik.values.swapps[index]

		if (Array.isArray(brands[category]) && brands[category].length) {
			const data = brands[category].map(brandItem => ({
				id: `${brandItem.id}`,
				value: `${brandItem.id}`,
				label: brandItem.name,
			}))

			return (
				<Wrapper key={`swappBrand_${index}-${Math.ceil(Math.random() * 1000)}`}>
					<SelectDropdown
						id={`swappBrand_${index}`}
						title={t('common:Proizvođač')}
						isSearchable
						isDisabled={brandsLoading[category]}
						value={formik.values.swapps[index].brand || -1}
						options={data}
						onChange={selectedBrand => {
							formik.setFieldValue(`swapps.${index}.brand`, selectedBrand)
							
							// Clear Product
							formik.setFieldValue(`swapps.${index}.product`, -1)

							this.fetchProducts(Number(selectedBrand), Number(category))
						}}
					/>
				</Wrapper>
			)
		}

		return null
	}

	renderModel = index => {
		const { formik, i18n: { t } } = this.props
		const { products, productsLoading } = this.state

		if (!Array.isArray(formik.values.swapps) || !formik.values.swapps[index]) return null

		const { brand, product } = formik.values.swapps[index]

		if (Array.isArray(products[brand]) && products[brand].length) {
			const data = products[brand].map(product => ({
				id: `${product.id}`,
				value: `${product.id}`,
				label: product.model,
			}))

			return (
				<Wrapper key={`swappProduct_${index}-${Math.ceil(Math.random() * 1000)}`}>
					<SelectDropdown
						id={`swappProduct_${index}`}
						title={t('common:Model')}
						isSearchable
						isDisabled={productsLoading[brand]}
						value={formik.values.swapps[index].product || -1}
						options={data}
						onChange={selectedProduct => {
							formik.setFieldValue(`swapps.${index}.product`, selectedProduct)
						}}
					/>
				</Wrapper>
			)
		}

		return null
	}

	renderSpecification = ({ index, specification, number }) => {
		const { formik } = this.props

		if (!Array.isArray(formik.values.swapps) || !formik.values.swapps[index]) return null

		const options = Array.isArray(specification.options)
			? specification.options.map(option => ({
				id: `option-${option}`,
				label: `${option}`,
				value: `${option}`
			}))
			: []

		return (
			<Wrapper key={`swappSpecification_${index}-${specification.id}`}>
				<SelectDropdown
					id={`swappSpecification_${index}-${specification.id}`}
					title={specification.title}
					isSearchable
					isDisabled={!options.length}
					value={formik.values.swapps[index][`specificationValue${number}`] || -1}
					options={options}
					onChange={selectedOption => {
						console.log('selectedOption', selectedOption)
						formik.setFieldValue(`swapps.${index}.specification${number}`, specification.id)
						formik.setFieldValue(`swapps.${index}.specificationValue${number}`, selectedOption)
					}}
				/>
			</Wrapper>
		)
	}

	renderAddButton = arrayHelpers => {
		const { formik } = this.props
		const { swapps } = formik.values

		return (
			<AddButton
				key='add-swapps'
				onClick={() => { arrayHelpers.insert(swapps.length, { category: -1 }) }}
			/>
		)
	}

	render() {
		const { category, formik } = this.props
		const { swapps } = formik.values

		return category && category.swappPresence ? (
			<React.Fragment>
				{this.renderSwappType()}
				{formik.values.swapp !== 'No' ? (
					<FieldArray
						name='swapps'
						render={arrayHelpers => (
							<React.Fragment>
								{swapps && swapps.length ? swapps.map((swapp, index) => this.renderSwapp(index, arrayHelpers)) : null}

								{swapps && swapps.length < this.maxSwapCount ? this.renderAddButton(arrayHelpers) : null}
							</React.Fragment>
						)}
					/>
				) : null}
			</React.Fragment>
		) : null
	}
}

const mapStateToProps = (state) => {
  return {
		category: getCurrentSubCategory(state),
		categories: getCategoriesWithSwappPresence(state)
  }
}

const enhance = compose(
	connect(mapStateToProps, { fetchCategories }),
	formikConnect,
	withTranslation
)

export default enhance(Swapps)
