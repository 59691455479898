import PropTypes from 'prop-types'
import React from 'react'
import { Select } from 'SRC/ui/FormElements'
import { connect } from 'react-redux'
import { formValueSelector, change } from 'redux-form'
import { isObjectEquals } from 'src/utils/Utils'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { AdsProductsApi } from 'SRC/modules/ads/products/api'
import { ServiceTypesApi } from 'SRC/modules/ads/serviceTypes/api'
import { getBrands } from 'SRC/modules/ads/brands/selectors'
import { getProducts } from 'SRC/modules/ads/products/selectors'
import { getServices } from 'SRC/modules/ads/services/selectors/getServices'
import { getServiceTypes } from 'SRC/modules/ads/serviceTypes/selectors'
import { ReduxFormRequired } from 'Core/validators/reduxForm'
import config from 'SRC/config/config.json'

export class BrandAndProduct extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isProductsLoading: !this.props.products.length,
      brands: this.prepareBrands(),
      products: this.prepareProducts(this.props.products),
      isServiceTypesLoading: !this.props.serviceTypes.length,
      serviceTypes: this.prepareServiceTypes(this.props.serviceTypes),
      productSpecifications: null
    }
  }

  componentDidUpdate = (prevProps) => {
    if (!isObjectEquals(prevProps.brands, this.props.brands)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ brands: [...this.prepareBrands()] })
    }
  }

  getRenderPermissions = () => {
    let isBrandShouldRender = false
    let isProductShouldRender = false
    let isServiceShouldRender = false
    let isServiceTypeShouldRender = false
    if (this.props.category) {
      if (this.props.category.requiredAdOptions === 'BrandModel') {
        isBrandShouldRender = true
        isProductShouldRender = true
      } else if (this.props.category.requiredAdOptions === 'BrandOnly') {
        isBrandShouldRender = true
      } else if (this.props.category.requiredAdOptions === 'Services') {
        isServiceShouldRender = true
        isServiceTypeShouldRender = true
      }
    }
    return {isBrandShouldRender, isProductShouldRender, isServiceShouldRender, isServiceTypeShouldRender}
  }

  clearOldSpecValues = async () => {
    const { specificationValues } = this.props
    if (this.state.productSpecifications && this.state.productSpecifications.length) {
      for (let spec of this.state.productSpecifications) {
        const specificationIndex = specificationValues && specificationValues
          .findIndex(specification => specification.id === spec.id)

        await this.props.change(this.props.formName, `specifications[${specificationIndex}].value`, null)
      }
    }

    await this.setState({ productSpecifications: null })
  }

  prepareBrands = () => {
    const stared = this.staredBrands()
    return [
      ...stared,
      {id: 0, label: null, value: 0, disabled: true},
      ...this.props.brands.map(({id, name}) => ({id, label: name, value: id}))
    ]
  }

  staredBrands = () => {
    if (!this.props.category) return []
    return this.props.brands
      .filter(brand => brand.stared)
      .map(({id, name}) => ({id, label: name, value: id, key: `stared_${id}`}))
  }

  getBrandField = () => ({
    id: 'proizvodjac',
    label: 'Proizvođač',
    name: 'brand',
    isRequired: true,
    options: this.state.brands,
    onChange: this.onBrandChange,
    validate: ReduxFormRequired
  })

  getProductField = () => ({
    id: 'model',
    label: 'Model',
    name: 'product',
    isRequired: true,
    disabled: this.state.isProductsLoading,
    options: this.state.products,
    onChange: this.onProductChange,
    validate: ReduxFormRequired
  })

  getServicesField = () => ({
    id: 'service',
    label: 'Usluga',
    name: 'service',
    isRequired: true,
    onChange: this.onServiceChange,
    options: this.props.services.map(({id, name}) => ({id, label: name, value: id})),
    validate: ReduxFormRequired
  })

  getServiceTypesField = () => ({
    id: 'serviceType',
    label: 'Tip usluge',
    name: 'serviceType',
    isRequired: true,
    disabled: this.state.isServiceTypesLoading,
    options: this.state.serviceTypes,
    validate: ReduxFormRequired
  })

  onBrandChange = async e => {
    const getDefaultSpecValuesForModels = config.adForm.defaultSpecValuesForModels
    const value = Number(e.target.value)
    await this.loadProductsToState(value)
    if (getDefaultSpecValuesForModels) {
      if (this.state.productSpecifications && this.state.productSpecifications.length) {
        await this.clearOldSpecValues()
      }
    }
  }

  onProductChange = async e => {
    const { selectedBrand, specificationValues } = this.props
    const getDefaultSpecValuesForModels = config.adForm.defaultSpecValuesForModels
    if (getDefaultSpecValuesForModels) {
      if (this.state.productSpecifications && this.state.productSpecifications.length) {
        await this.clearOldSpecValues()
      }
      const product = Number(e.target.value)
      if (selectedBrand) {
        const api = new AdsProductsApi()
        const result = await api.fetchDefaultSpecsForProductByBrand(Number(selectedBrand), product)
        if (result && result.length) {
          const productSpecifications = []
          for (let productSpec of result) {
            productSpecifications.push({
              id: productSpec.specification.id,
              value: productSpec.value
            })

            const specificationIndex = specificationValues && specificationValues
              .findIndex(spec => spec.id === productSpec.specification.id)

            if (specificationIndex > -1) {
              await this.props.change(this.props.formName, `specifications[${specificationIndex}].value`, productSpec.value)
            }
          }

          this.setState({ productSpecifications })
        }
      }
    }
  }

  loadProductsToState = async brand => {
    if (this.props.category && this.props.category.id && this.getRenderPermissions().isProductShouldRender) {
      await this.setState({
        ...this.state,
        isProductsLoading: true
      })
      const api = new AdsProductsApi()
      const products = await api.fetchProductByBrandAndCategory({brand, category: this.props.category.id})
      await this.setState({
        ...this.state,
        products: this.prepareProducts(products),
        isProductsLoading: !products.length
      })
    }
  }

  prepareProducts = (products = []) => products.map(({id, model}) => ({id, value: id, label: model}))

  onServiceChange = async e => {
    const service = Number(e.target.value)
    await this.loadServiceTypesToState(service)
  }

  loadServiceTypesToState = async service => {
    if (this.props.category && this.props.category.id && service && this.getRenderPermissions().isServiceTypeShouldRender) {
      await this.setState({...this.state, isServiceTypesLoading: true})
      const api = new ServiceTypesApi()
      const serviceTypes = await api.fetchServiceTypesByServiceAndCategory({service, category: this.props.category.id})
      await this.setState({
        ...this.state,
        serviceTypes: this.prepareServiceTypes(serviceTypes),
        isServiceTypesLoading: !serviceTypes.length
      })
    }
  }

  prepareServiceTypes = (serviceTypes = []) => serviceTypes.map(({id, name}) => ({id, label: name, value: id}))

  render () {
    const {isBrandShouldRender, isProductShouldRender, isServiceShouldRender, isServiceTypeShouldRender} = this.getRenderPermissions()
    return [
      isBrandShouldRender ? <Select {...this.getBrandField()} key='brand' /> : null,
      isProductShouldRender ? <Select {...this.getProductField()} key='product' /> : null,
      isServiceShouldRender ? <Select {...this.getServicesField()} key='service' /> : null,
      isServiceTypeShouldRender ? <Select {...this.getServiceTypesField()} key='serviceType' /> : null
    ]
  }
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.formName)
  const selectedBrand = selector(state, 'brand')
  const specificationValues = selector(state, 'specifications')
  return {
    category: getCurrentSubCategory(state),
    brands: getBrands(state),
    products: getProducts(state),
    services: getServices(state),
    serviceTypes: getServiceTypes(state),
    selectedBrand,
    specificationValues
  }
}

BrandAndProduct.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number.isRequired,
    requiredAdOptions: PropTypes.oneOf(['BrandModel', 'BrandOnly', 'Services', null])
  }),
  brands: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  services: PropTypes.array.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  selectedBrand: PropTypes.number,
  specificationValues: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.any
  })),
  formName: PropTypes.oneOf(['addAdBasic', 'addAdDetail', 'editAd']),
  change: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {change})(BrandAndProduct)
