import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { connect as connectFormik } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { adPriceTypes } from 'Core/constants'
import { Checkbox } from 'SRC/ui/FormElements'
import { Header } from 'SRC/modules/ads/shared/components/FormElements'
import { Price } from '../Price'
import { Description } from '../Description'
import { Swapps } from '../Swapps'

const Container = ({
  category,
  hasPartnerAddon,
  hasPriceFromAddon,
  hasPriceRangeSeparateAddon,
  hasPriceFromToAddon,
  hasPriceOnRequestAddon,
  formType,
  formik
}) => {
  const { t } = useTranslation('common')

  const descriptionLabelText = t('shortDescription')

  return (
    <React.Fragment>
      <Header title={t('Cijena, opis i zamjena')} key='header' />

      <div className='ads-add__price-description'>
        <div className='left-inputs' key='left-fields'>
          {/* Price */}
          <Price
            hasPartnerAddon={hasPartnerAddon}
            hasPriceFromAddon={hasPriceFromAddon}
            hasPriceRangeSeparateAddon={hasPriceRangeSeparateAddon}
            hasPriceFromToAddon={hasPriceFromToAddon}
            hasPriceOnRequestAddon={hasPriceOnRequestAddon}
            formType={formType}
          />
        </div>

        {/* Description */}
        <Description key='description-field' label={descriptionLabelText} />
      </div>
      
      {/* Swapps */}
      <Swapps key='swap-field' />
    </React.Fragment>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    category: getCurrentSubCategory(state)
  }
}

Container.displayName = 'PriceSwappDescription'

const enhance = compose(
  connect(mapStateToProps),
  connectFormik
)

export default enhance(Container)
