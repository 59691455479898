import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import { TextInput } from 'SRC/ui/FormElements'
import { priceMask } from 'Core/masks'
import { ReduxFormRequired } from 'Core/validators/reduxForm'

class Price extends React.Component {
  componentDidUpdate () {
    const { priceMasked } = this.props
    this.props.change(this.props.formName, 'priceUnmasked', priceMasked.toString().replace(',', '.'))
  }

  shouldComponentUpdate (nextProps) {
    return nextProps.priceMasked && this.props.priceMasked !== nextProps.priceMasked
  }
  render () {
    return [<TextInput
      id='price'
      label='Cijena'
      isRequired
      name='price'
      measure='€'
      mask={priceMask}
      validate={ReduxFormRequired}
    />, <input id='priceUnmasked' type='hidden' name='priceUnmasked' />]
  }
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.formName)
  return {
    priceMasked: selector(state, 'price')
  }
}

Price.propTypes = {
  priceMasked: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired
}

Price.defaultProps = {
  priceMasked: ''
}

export default connect(mapStateToProps, {change})(Price)
