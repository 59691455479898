import PropTypes from 'prop-types'
import React from 'react'
import { useFormikContext, ErrorMessage } from 'formik'
import StarRatings from 'react-star-ratings'
import { Wrapper } from 'SRC/ui/FormElementsNew'

export const StarSpecification = ({ spec }) => {
	const formik = useFormikContext()

	const value = formik.values.specifications ? formik.values.specifications[spec.index].value : null

	const rating = value && !isNaN(value) ? Number(value) : 0

	return (
		<Wrapper
			isError={formik.touched.specifications && formik.errors.specifications && formik.errors.specifications[spec.index]}
			className='form-group form-group-star-specification'
		>
			<label>{spec.label}: {spec.isRequired ? <span className='required'>*</span> : null}</label>

			<StarRatings
				rating={rating}
				numberOfStars={5}
				starDimension={23}
				starSpacing={3}
				name={spec.name}
				changeRating={newRating => { formik.setFieldValue(`specifications.${spec.index}.value`, newRating) }}
				starRatedColor='#e6432f'
			/>

			<ErrorMessage
				name={`specifications.${spec.index}`}
				render={msg => <span className='form-group__error-message'>{msg}</span>}
			/>
		</Wrapper>
	)

}
