import React from 'react'
import { useFormikContext, ErrorMessage } from 'formik'
import { rationalNumbers } from 'Core/masks'
import useTranslation from 'next-translate/useTranslation'

import { TextInputNew, Wrapper } from 'SRC/ui/FormElementsNew'

export const AuctionPrice = ({ priceType }) => {
	const formik = useFormikContext()
  const { t } = useTranslation('common')

	const error = formik.touched.price && formik.errors.price

	return (
		<Wrapper isError={error}>
			<TextInputNew
				id='price'
				name='price'
				title={priceType ? priceType.priceName : t('Cijena')}
				isRequired
				value={formik.values.price}
				mask={rationalNumbers}
				measure='€'
				onChange={async textValue => {
					console.log('AuctionPrice price', textValue)
					await formik.setFieldValue('price', textValue)

					if (!formik.touched.price) formik.setFieldTouched('price', true)

					formik.setErrors({
						...formik.errors,
						price: formik.touched.price && [0, -1].includes(Number(textValue))
							? `${priceType ? priceType.priceName : t('Cijena')} ${t('je obavezno polje')}`
							: undefined
					})
				}}
			/>

			<ErrorMessage
				name='price'
				render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
			/>
		</Wrapper>
	)
}
