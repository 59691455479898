import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getPriceTypes } from 'SRC/modules/users/Profile/selectors'
import { adPriceTypes } from 'Core/constants'
import { Checkbox } from 'SRC/ui/FormElements'
import { Header } from '../../Header'
import { Price } from '../Price'
import { AuctionPrice } from '../AuctionPrice'
import { DiscountPrice } from '../DiscountPrice'
import { PriceType } from '../PriceType'
import { PriceTypeForAddons } from '../PriceTypeForAddons'
import { PricePercentage } from '../PricePercentage'
import { PriceFrom } from '../PriceFrom'
import { PriceTo } from '../PriceTo'
import { DiscountCardPrice } from '../DiscountCardPrice'
import { Description } from '../Description'
import { Swap } from '../Swap'
import config from 'SRC/config/config.json'

export class Container extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      showPriceOnRequest: false
    }
  }

  async componentDidMount () {
    const { priceOnRequest, formName } = this.props
    const showPriceOnRequest = this.getShowPriceOnRequest()
    const showAuctionPriceOnRequest = this.getShowAuctionPriceOnRequest()

    if (!showPriceOnRequest && !showAuctionPriceOnRequest && priceOnRequest) {
      await this.props.change(formName, 'priceOnRequest', false)
    }

    this.setState({ showPriceOnRequest })
  }

  async componentDidUpdate (prevProps) {
    const { actualPrice, showDiscountCardPrice, discountCardPricePercentage, formName } = this.props
    if (actualPrice !== prevProps.actualPrice && showDiscountCardPrice) {
      const percentage = discountCardPricePercentage
      if (percentage && Number(percentage)) {
        const priceWithDiscountCard = actualPrice - (actualPrice * (percentage / 100))
        await this.props.change(formName, 'discountCardPrice', priceWithDiscountCard)
      }
    }
  }

  getShowPriceOnRequest = () => {
    const { hasPriceOnRequestAddon, category } = this.props

    if (category && category.requiredAdOptions === 'Services') {
      return true
    }

    if (category && category.allowPriceOnRequest) {
      return hasPriceOnRequestAddon
    }

    return false
  }

  getShowAuctionPriceOnRequest = () => {
    const { showAuctionPrice, auctionPriceType } = this.props

    if (showAuctionPrice && auctionPriceType && auctionPriceType.hasPriceOnRequest) {
      return true
    }

    return false
  }

  getPriceTypeForAddonsOptions = () => {
    const { hasPartnerAddon, hasPriceFromToAddon, hasPriceRangeSeparateAddon, hasPriceFromAddon, priceTypes } = this.props

    const options = [
      {
        id: adPriceTypes.retail.id,
        label: adPriceTypes.retail.title,
        value: adPriceTypes.retail.id
      }
    ]
    if (hasPartnerAddon) {
      options.push({
        id: adPriceTypes.discountCard.id,
        label: adPriceTypes.discountCard.title,
        value: adPriceTypes.discountCard.id
      })
    }
    if (hasPriceFromToAddon || hasPriceRangeSeparateAddon) {
      options.push({
        id: adPriceTypes.fromTo.id,
        label: adPriceTypes.fromTo.title,
        value: adPriceTypes.fromTo.id
      })
    }
    if (hasPriceFromAddon) {
      options.push({
        id: adPriceTypes.from.id,
        label: adPriceTypes.from.title,
        value: adPriceTypes.from.id
      })
    }
    if (Array.isArray(priceTypes) && priceTypes.length) {
      priceTypes.forEach(pt => {
        options.push({
          id: pt.id,
          label: pt.name,
          value: pt.name
        })
      })
    }

    return options
  }

  onPriceTypeForAddonsChange = async e => {
    const { auctionPriceType } = this.props

    const value = e.target.value

    if (value) {
      switch (value) {
        case `${adPriceTypes.retail.id}`:
          await this.props.change(this.props.formName, 'typeOfPrice', null)
          await this.props.change(this.props.formName, 'pricePercentage', null)
          await this.props.change(this.props.formName, 'discountCardPrice', null)
          await this.props.change(this.props.formName, 'priceFrom', null)
          await this.props.change(this.props.formName, 'priceTo', null)
          break
        case `${adPriceTypes.discountCard.id}`:
          await this.props.change(this.props.formName, 'typeOfPrice', null)
          await this.props.change(this.props.formName, 'priceFrom', null)
          await this.props.change(this.props.formName, 'priceTo', null)
          break
        case `${adPriceTypes.fromTo.id}`:
          await this.props.change(this.props.formName, 'typeOfPrice', null)
          await this.props.change(this.props.formName, 'price', null)
          await this.props.change(this.props.formName, 'priceUnmasked', null)
          await this.props.change(this.props.formName, 'discountedPrice', null)
          await this.props.change(this.props.formName, 'discountedPriceUnmasked', null)
          await this.props.change(this.props.formName, 'pricePercentage', null)
          await this.props.change(this.props.formName, 'discountCardPrice', null)
          break
        case `${adPriceTypes.from.id}`:
          await this.props.change(this.props.formName, 'typeOfPrice', null)
          await this.props.change(this.props.formName, 'price', null)
          await this.props.change(this.props.formName, 'priceUnmasked', null)
          await this.props.change(this.props.formName, 'discountedPrice', null)
          await this.props.change(this.props.formName, 'discountedPriceUnmasked', null)
          await this.props.change(this.props.formName, 'pricePercentage', null)
          await this.props.change(this.props.formName, 'discountCardPrice', null)
          await this.props.change(this.props.formName, 'priceTo', null)
          break
        case 'Aukcija':
          console.log('auctionPriceType', auctionPriceType)
          if (auctionPriceType) await this.props.change(this.props.formName, 'typeOfPrice', auctionPriceType.id)
          await this.props.change(this.props.formName, 'price', null)
          await this.props.change(this.props.formName, 'priceUnmasked', null)
          await this.props.change(this.props.formName, 'discountedPrice', null)
          await this.props.change(this.props.formName, 'discountedPriceUnmasked', null)
          await this.props.change(this.props.formName, 'pricePercentage', null)
          await this.props.change(this.props.formName, 'discountCardPrice', null)
          await this.props.change(this.props.formName, 'priceFrom', null)
          await this.props.change(this.props.formName, 'priceTo', null)
          break
        default:
          await this.props.change(this.props.formName, 'typeOfPrice', null)
          await this.props.change(this.props.formName, 'price', null)
          await this.props.change(this.props.formName, 'priceUnmasked', null)
          await this.props.change(this.props.formName, 'discountedPrice', null)
          await this.props.change(this.props.formName, 'discountedPriceUnmasked', null)
          await this.props.change(this.props.formName, 'pricePercentage', null)
          await this.props.change(this.props.formName, 'discountCardPrice', null)
          await this.props.change(this.props.formName, 'priceFrom', null)
          await this.props.change(this.props.formName, 'priceTo', null)
          break
      }
    }
  }

  onPricePercentageChange = async e => {
    const { actualPrice, formName } = this.props
    let percentage = Number(e.target.value)
    if (percentage && percentage > 99) {
      percentage = 99
    }

    const priceWithDiscountCard = actualPrice - (actualPrice * (percentage / 100))
    setTimeout(() => {
      this.props.change(formName, 'discountCardPrice', priceWithDiscountCard)
    }, 1000)
  }

  onDiscountCardPriceChange = async value => {
    const { actualPrice, formName, discountCardPricePercentage } = this.props
    const discountCardPrice = value
    if (discountCardPrice && Number(discountCardPrice)) {
      const updatedPercentage = 100 - ((discountCardPrice / actualPrice) * 100)
      if (updatedPercentage <= 99 && updatedPercentage >= 1) {
        await this.props.change(formName, 'pricePercentage', updatedPercentage.toFixed(2))
      } else {
        await this.props.change(formName, 'discountCardPrice',
          actualPrice - (actualPrice * (discountCardPricePercentage / 100)))
      }
    }
  }

  onPriceOnRequestChange = async e => {
    const checked = e.target.checked
    if (checked) {
      await this.props.change(this.props.formName, 'price', null)
      await this.props.change(this.props.formName, 'priceUnmasked', null)
      await this.props.change(this.props.formName, 'discountedPrice', null)
      await this.props.change(this.props.formName, 'discountedPriceUnmasked', null)
      await this.props.change(this.props.formName, 'pricePercentage', null)
      await this.props.change(this.props.formName, 'discountCardPrice', null)
      await this.props.change(this.props.formName, 'priceFrom', null)
      await this.props.change(this.props.formName, 'priceTo', null)
    }
  }

  renderAuctionPrice = () => {
    const { formName, priceOnRequest, auctionPriceType } = this.props

    if (auctionPriceType) {
      return (
        <React.Fragment>
          {!priceOnRequest ? <AuctionPrice formName={formName} /> : null}

          {auctionPriceType.hasPriceOnRequest ? (
            <div style={{ width: '100%', float: 'left' }} key='price-fields'>
              <Checkbox id='auctionPriceOnRequest' label={auctionPriceType.priceOnRequestName} name='priceOnRequest'
                onInput={this.onPriceOnRequestChange} />
            </div>
          ) : null}
        </React.Fragment>
      )
    }

    return null
  }

  renderRegularPrice = () => {
    const { showAuctionPrice, priceOnRequest, priceTypeForAddons, formName, isBasicForm } = this.props

    if (showAuctionPrice && priceTypeForAddons === 'Aukcija') return this.renderAuctionPrice()

    if (priceOnRequest) return null

    if ((!priceTypeForAddons || ![adPriceTypes.fromTo.id, adPriceTypes.from.id].includes(Number(priceTypeForAddons)))) {
      return (
        <React.Fragment>
          <Price formName={formName} />

          {!isBasicForm ? <DiscountPrice formName={formName} /> : null}
        </React.Fragment>
      )
    }

    return null
  }

  renderPriceTypeForAddons = () => {
    const {
      hasPartnerAddon,
      hasPriceFromToAddon,
      hasPriceFromAddon,
      hasPriceRangeSeparateAddon,
      priceOnRequest,
      priceTypes
    } = this.props

    if (priceOnRequest) return null

    const hasAddons = hasPartnerAddon || hasPriceFromToAddon || hasPriceRangeSeparateAddon || hasPriceFromAddon
    const hasPriceTypes = Array.isArray(priceTypes) && priceTypes.length

    if (hasAddons || hasPriceTypes) {
      return (
        <PriceTypeForAddons
          options={this.getPriceTypeForAddonsOptions()}
          onChange={this.onPriceTypeForAddonsChange}
        />
      )
    }

    return null
  }

  render () {
    const { formName, swap, adsSwapps, hasPriceRangeSeparateAddon, showDiscountCardPrice, showFromToPrice,
      showFromPriceOnly, priceTypeForAddons, priceOnRequest
    } = this.props

    const { showPriceOnRequest } = this.state

    return [
      <Header title='Cijena, opis i zamjena' key='header' />,
      <div className='ads-add__price-description'>
        <div className='left-inputs' key='left-fields'>
          {showPriceOnRequest
            ? <div style={{ width: '100%', float: 'left' }} key='price-fields'>
              <Checkbox id='priceOnRequest' label='Cijena na upit' name='priceOnRequest'
                onInput={this.onPriceOnRequestChange} />
            </div>
            : null
          }

          {this.renderRegularPrice()}
          {!priceOnRequest && priceTypeForAddons !== 'Aukcija' ? <PriceType /> : null}

          {this.renderPriceTypeForAddons()}

          {showDiscountCardPrice
            ? <div key='price-discount-card' style={{ width: '100%', float: 'left' }}>
              <PricePercentage onChange={this.onPricePercentageChange} />
              <DiscountCardPrice onChange={this.onDiscountCardPriceChange} />
            </div>
            : null
          }
          {showFromToPrice
            ? <div key='price-from-to' style={{ width: '100%', float: 'left' }}>
              <PriceFrom formName={formName} isRequired={!hasPriceRangeSeparateAddon} />
              <PriceTo formName={formName} isRequired={!hasPriceRangeSeparateAddon} />
            </div>
            : null
          }
          {showFromPriceOnly
            ? <div key='price-from-to' style={{ width: '100%', float: 'left' }}>
              <PriceFrom formName={formName} isRequired />
            </div>
            : null}
        </div>
        <Description key='description-field' />
      </div>,
      <Swap key='swap-field' formName={formName} swapp={swap} adsSwapps={adsSwapps} />
    ]
  }
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.formName)

  const priceTypes = getPriceTypes(state)

  const auctionPriceType = Array.isArray(priceTypes) ? priceTypes.find(pt => pt.name === 'Aukcija') : null

  const priceTypeForAddons = selector(state, 'priceTypeForAddons')
  const price = selector(state, 'price')
  const discountedPrice = selector(state, 'discountedPrice')
  const discountCardPricePercentage = selector(state, 'pricePercentage')
  const priceOnRequest = selector(state, 'priceOnRequest')

  const actualPrice = discountedPrice || price

  const showDiscountCardPrice = config.discountCard && priceTypeForAddons && !priceOnRequest &&
    Number(priceTypeForAddons) === adPriceTypes.discountCard.id && ownProps.hasPartnerAddon

  const showFromToPrice = priceTypeForAddons && !priceOnRequest &&
    Number(priceTypeForAddons) === adPriceTypes.fromTo.id && (ownProps.hasPriceFromToAddon || ownProps.hasPriceRangeSeparateAddon)

  const showFromPriceOnly = priceTypeForAddons && !priceOnRequest &&
    Number(priceTypeForAddons) === adPriceTypes.from.id && ownProps.hasPriceFromAddon

  const category = getCurrentSubCategory(state)

  return {
    category,
    showDiscountCardPrice,
    showFromToPrice,
    showFromPriceOnly,
    actualPrice,
    discountCardPricePercentage,
    priceTypeForAddons,
    priceOnRequest,
    priceTypes,
    showAuctionPrice: Boolean(auctionPriceType),
    auctionPriceType
  }
}

Container.displayName = 'Props'

Container.propTypes = {
  category: PropTypes.shape({
    allowPriceOnRequest: PropTypes.bool,
    requiredAdOptions: PropTypes.string
  }).isRequired,
  adsSwapps: PropTypes.any,
  formName: PropTypes.any,
  swap: PropTypes.any,
  actualPrice: PropTypes.number.isRequired,
  discountCardPricePercentage: PropTypes.number.isRequired,
  priceTypeForAddons: PropTypes.number.isRequired,
  priceOnRequest: PropTypes.bool.isRequired,
  isBasicForm: PropTypes.bool,
  hasPartnerAddon: PropTypes.bool.isRequired,
  hasPriceFromToAddon: PropTypes.bool.isRequired,
  hasPriceRangeSeparateAddon: PropTypes.bool.isRequired,
  hasPriceFromAddon: PropTypes.bool.isRequired,
  hasPriceOnRequestAddon: PropTypes.bool.isRequired,
  showDiscountCardPrice: PropTypes.bool.isRequired,
  showFromToPrice: PropTypes.bool.isRequired,
  showFromPriceOnly: PropTypes.bool.isRequired,
  priceTypes: PropTypes.array,
  auctionPriceType: PropTypes.object,
  showAuctionPrice: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { change })(Container)
