import React from 'react'
import { adPriceTypes } from 'Core/constants'
import { Select } from 'SRC/ui/FormElements'
import { ReduxFormRequired } from 'Core/validators/reduxForm'

const options = [
  {
    id: adPriceTypes.fixed.id,
    label: adPriceTypes.fixed.title,
    value: adPriceTypes.fixed.id
  },
  {
    id: adPriceTypes.negotiated.id,
    label: adPriceTypes.negotiated.title,
    value: adPriceTypes.negotiated.id
  },
  {
    id: adPriceTypes.urgent.id,
    label: adPriceTypes.urgent.title,
    value: adPriceTypes.urgent.id
  }
]

export const PriceType = () =>
  <Select
    id='priceType'
    label='Tip cijene'
    isRequired
    name='priceType'
    options={options}
    validate={ReduxFormRequired}
  />
