import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'
import { useFormikContext, ErrorMessage } from 'formik'
import { TextInputNew, CheckboxNew, Wrapper } from 'SRC/ui/FormElementsNew'

export const Title = ({
  placeholder,
  hasAdTitleAddon,
  ...restProps
}) => {
  const formik = useFormikContext()
  const { t } = useTranslation('common')

  const isError = formik.errors && formik.errors.title && formik.touched && formik.touched.title

  return (
    <React.Fragment>
      <Wrapper isError={isError} isFullWidth>
        <TextInputNew
          key='title'
          id='title'
          name='title'
          title={t('Naslov oglasa')}
          placeholder={t('Upiši šta prodaješ')}
          allowedCharacters={['letters', 'numbers', 'characters']}
          onChange={value => formik.setFieldValue('title', value)}
          value={formik.values.title}
          isFullWidth
          isRequired
          {...restProps}
        />
        <ErrorMessage name='title' render={msg => <span className='form-group__error-message'>{msg}</span>} />
      </Wrapper>

      {hasAdTitleAddon ? (
        <div className='dodatna-oprema-checkbox'>
          <CheckboxNew
            key='use-title'
            id='useTitleForCompiledTitle'
            title={t('Primjeni na Naslov oglasa')}
            isChecked={formik.values.useTitleForCompiledTitle}
            onChange={e => { 
              formik.setFieldValue('useTitleForCompiledTitle', e.target.checked)
            }}
          />
        </div>
        ) : null
      }
    </React.Fragment>
  )
}

Title.propTypes = {
  placeholder: PropTypes.string,
  hasAdTitleAddon: PropTypes.bool
}
