import PropTypes from 'prop-types'
import React from 'react'

export const Header = ({title}) => (
  <div className='oglas-form-header'>
    <p>{title}</p>
  </div>
)

Header.propTypes = {
  title: PropTypes.string.isRequired
}
