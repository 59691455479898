import React from 'react'
import { useFormikContext } from 'formik'
import { rationalNumbers } from 'Core/masks'
import config from 'SRC/config/config.json'

import { TextInputNew, Wrapper } from 'SRC/ui/FormElementsNew'

import { updateDiscountCardPrice } from '../functions'

export const DiscountPrice = () => {
	const formik = useFormikContext()

	const { adForm: { adPriceTypes } } = config

	const error = formik.touched.discountedPrice && formik.errors.discountedPrice

	return (
		<Wrapper isError={error}>
			<TextInputNew
				id='discountedPrice'
				name='discountedPrice'
				title='Cijena sa popustom'
				value={formik.values.discountedPrice}
				mask={rationalNumbers}
				allowedCharacters={['rational']}
				measure='€'
				onChange={async textValue => {
					console.log('discountedPrice', textValue)
					const priceValue = Number(formik.values.price)

					const actualValue = Number(textValue) < priceValue ? textValue : - 1

					await formik.setFieldValue('discountedPrice', actualValue)

					if (Number(formik.values.priceTypeForAddons) === adPriceTypes.discountCard.id) {
						await updateDiscountCardPrice(formik, { price: formik.values.price, discountedPrice: actualValue})
					}

					if (!formik.touched.discountedPrice) formik.setFieldTouched('discountedPrice', true)
				}}
			/>
		</Wrapper>
	)
}
