import React from 'react'
import PropTypes from 'prop-types'
import { Arrows } from '../Arrows'
import { RemoveBtn } from '../RemoveBtn'
import { ItemImage } from '../ItemImage'
import { RotateLeftBtn } from '../RotateLeftBtn'
import { RotateRightBtn } from '../RotateRightBtn'
import config from 'SRC/config/config.json'

const getFd = photo => {
  if (photo.isUploaded) {
    if (photo.isUploading) return photo.fd
    return `${config.mediaCDN}${photo.fd}`
  } else {
    return photo.fd.replace(config.mediaCDN, `${config.mediaCDN}size/236x176/`)
  }
}

export const Item = ({
  photo,
  photosCount,
  index,
  onIncreasePriority,
  onDecreasePriority,
  onPhotoDeleted,
  onDragStart,
  onDragEnter,
  onDragLeave,
  onDragOver,
  onDrop,
  onRotateLeft,
  onRotateRight
}) => {
  const hasUpArrow = index !== 0
  const hasDownArrow = index !== photosCount - 1

  return (
    <div className='ads-add__photos-list-view-item' draggable onDragStart={onDragStart}
      onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDragOver={onDragOver} onDrop={onDrop} data-fd={photo.fd}>
      <ItemImage isPrimary={index === 0} priority={index + 1}
        fd={getFd(photo)}
      />
      <RemoveBtn onClick={() => onPhotoDeleted ? onPhotoDeleted(photo.fd) : null} />
      <RotateLeftBtn onClick={() => onRotateLeft ? onRotateLeft(photo.fd) : null} />
      <RotateRightBtn onClick={() => onRotateRight ? onRotateRight(photo.fd) : null} />
      <Arrows hasUpArrow={hasUpArrow} onIncreasePriority={() => onIncreasePriority ? onIncreasePriority(photo.fd) : null}
        hasDownArrow={hasDownArrow} onDecreasePriority={() => onDecreasePriority ? onDecreasePriority(photo.fd) : null} />
    </div>
  )
}

Item.propTypes = {
  photo: PropTypes.shape({
    fd: PropTypes.string,
    isUploaded: PropTypes.bool
  }).isRequired,
  index: PropTypes.number.isRequired,
  photosCount: PropTypes.number.isRequired,
  onIncreasePriority: PropTypes.func.isRequired,
  onDecreasePriority: PropTypes.func.isRequired,
  onPhotoDeleted: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onDragEnter: PropTypes.func.isRequired,
  onDragLeave: PropTypes.func.isRequired,
  onDragOver: PropTypes.func.isRequired,
  onRotateLeft: PropTypes.func.isRequired,
  onRotateRight: PropTypes.func.isRequired
}
