import React from 'react'
import { SelectDropdown, Wrapper } from 'SRC/ui/FormElementsNew'
import useTranslation from 'next-translate/useTranslation'

import config from 'SRC/config/config.json'
import { useFormikContext } from 'formik'

const { adPriceTypes } = config.adForm

export const PriceType = () => {
	const formik = useFormikContext()
  const { t } = useTranslation('common')

	const options = [
		{
			id: `${adPriceTypes.fixed.id}`,
			label: t(adPriceTypes.fixed.title),
			value: `${adPriceTypes.fixed.id}`
		},
		{
			id: `${adPriceTypes.negotiated.id}`,
			label: t(adPriceTypes.negotiated.title),
			value: `${adPriceTypes.negotiated.id}`
		},
		{
			id: `${adPriceTypes.urgent.id}`,
			label: t(adPriceTypes.urgent.title),
			value: `${adPriceTypes.urgent.id}`
		}
	]

	return (
		<Wrapper>
			<SelectDropdown
				id='priceType'
				title={t('Tip cijene')}
				isRequired
				isSearchable={false}
				isOptional={false}
				value={formik.values.priceType}
				options={options}
				onChange={priceType => {
					console.log('priceType selected', priceType)

					formik.setFieldValue('priceType',priceType)
				}}
			/>
		</Wrapper>
	)
}