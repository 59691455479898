import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'

const ImagePrimaryIcon = () => <ReactSVG src='/icons/broj-glavna-slika.svg' beforeInjection={svg => {
  svg.setAttribute('width', '24px')
  svg.setAttribute('height', '24px')
}} />
const ImageSecondaryIcon = () => <ReactSVG src='/icons/broj-ostale-slike.svg' beforeInjection={svg => {
  svg.setAttribute('width', '24px')
  svg.setAttribute('height', '24px')
}} />

export const ItemImage = ({ fd, isPrimary, priority }) => {
  return <div className='ads-add__photos-list-view-item-img' style={{
    backgroundImage: `url(${fd})`
  }}>
    <div className='ads-add__photos-list-view-item-img-icon-container'>
      <div className={`ads-add__photos-list-view-item-img-icon ${isPrimary ? 'primary' : 'secondary'}`}>
        <strong>{priority}</strong>
      </div>
      {isPrimary ? <ImagePrimaryIcon /> : <ImageSecondaryIcon />}
    </div>
  </div>
}

ItemImage.propTypes = {
  fd: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  priority: PropTypes.number.isRequired
}
