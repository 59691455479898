import PropTypes from 'prop-types'
import React from 'react'
import { swapOptions } from 'Core/constants'
import { connect } from 'react-redux'
import { Select, Hidden } from 'SRC/ui/FormElements'
import { getFlattenCategories, getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { FieldArray, change, arrayRemove } from 'redux-form'
import { RemoveButton } from '../RemoveButton'
import { AddButton } from '../AddButton'
import { AdsBrandsApi } from 'SRC/modules/ads/brands/api'
import { AdsProductsApi } from 'SRC/modules/ads/products/api'
import { Preloader } from 'SRC/ui/Preloader'
import { ReduxFormRequired } from 'Core/validators/reduxForm'

export class Container extends React.PureComponent {
  constructor (props) {
    super(props)

    this.maxSwapCount = 3
    this.fieldName = 'swapps'
    this.state = {
      swapCounter: Array.isArray(this.props.adsSwapps) && this.props.adsSwapps.length ? this.props.adsSwapps.length : 1,
      selectedSwap: this.props.swapp,
      swapps: Array.isArray(this.props.adsSwapps) && this.props.adsSwapps.length
        ? this.props.adsSwapps.map(() => this.getDefaultSwapState())
        : [this.getDefaultSwapState()],
      isLoading: Array.isArray(this.props.adsSwapps) && this.props.adsSwapps.length
    }
  }

  async componentDidMount () {
    if (Array.isArray(this.props.adsSwapps) && this.props.adsSwapps.length) {
      await Promise.all(this.props.adsSwapps.map(async (item, index) => {
        const swapps = {}
        if (item.category && item.category.id) {
          swapps.selectedCategory = item.category.id
          const searchedCategory = this.props.categories.filter(category => category.id === item.category.id)
          if (searchedCategory.length) {
            const categoryOptions = {}
            categoryOptions.requiredAdOptions = searchedCategory[0].requiredAdOptions
            if (searchedCategory[0].swappSpecification1) {
              categoryOptions.swappSpecification1 = searchedCategory[0].swappSpecification1
            }
            if (searchedCategory[0].swappSpecification2) {
              categoryOptions.swappSpecification2 = searchedCategory[0].swappSpecification2
            }
            if (Object.keys(categoryOptions).length) {
              swapps.categoryOptions = categoryOptions
            }
          }
          if (this.isBrandAvailableForCategory(swapps.categoryOptions)) {
            await this.loadBrandsToState(item.category.id, index)
          }
          if (this.isSpecificationsAvailableForCategory(swapps.categoryOptions)) {
            await this.loadSpecificationsToState(swapps.categoryOptions, index)
          }
        }
        if (item.brand && item.brand.id) {
          swapps.selectedBrand = item.brand.id
        }
        if (Object.keys(swapps)) {
          await this.saveSwappsToState(index, swapps)
        }
        if (item.brand && item.brand.id) {
          if (this.isProductAvailableForCategory(swapps.categoryOptions)) {
            await this.loadProductsToState(item.brand.id, index)
          }
        }
      }))
      await this.setState({...this.state, isLoading: false})
    }
  }

  getDefaultSwapState = () => ({
    selectedCategory: null,
    categoryOptions: {},
    brands: [],
    selectedBrand: null,
    isBrandsLoading: true,
    products: [],
    isProductsLoading: true,
    specificationValues1: [],
    specificationValues2: []
  })

  saveSwappsToState = async (index, swapFields, restFields = {}) => {
    await this.setState(
      prevState => {
        const newSwapps = prevState.swapps
        newSwapps[index] = {...prevState.swapps[index], ...swapFields}
        return {prevState, ...restFields}
      }
    )
  }

  getSwapField = () => ({
    id: 'swapp',
    label: 'Zamjena',
    name: 'swapp',
    options: swapOptions.map(({id, title, value}) => ({id, label: title, value})),
    onChange: this.onSwapChange,
    isRequired: true,
    validate: ReduxFormRequired
  })

  onSwapChange = async e => {
    const value = e.target.value
    const needResetState = this.state.selectedSwap && this.state.selectedSwap !== 'No' && (value === 'No' || !value)
    await this.setState(prevState => {
      return {
        ...this.state,
        selectedSwap: value,
        swapps: needResetState ? [this.getDefaultSwapState()] : prevState.swapps,
        swapCounter: 1
      }
    })
    if (needResetState) {
      this.props.change(this.props.formName, this.fieldName, [])
    }
  }

  renderSwapps = ({swapps, isLoading}) => isLoading
    ? <div className='ads-add__swap-row_loading'><Preloader /></div>
    : [
      swapps.map(
        (item, index) => {
          return <div
            className={`ads-add__swap-row ${index === 0 ? `ads-add__swap-row_first` : ''}`}
            key={`swapps-row-${index}`}
          >
            {
              this.getSwapItem(index).map(item => {
                switch (item.type) {
                  case 'button':
                    return <RemoveButton
                      index={index}
                      onClick={this.onRemoveSwappsClick}
                      key={`remove-swapps-${index}`}
                    />
                  case 'hidden':
                    return <Hidden name={item.name} key={item.id} />
                  default:
                    return <Select {...item} key={item.id} />
                }
              })
            }
          </div>
        }
      ),
      this.isShouldRenderAddButton()
        ? <AddButton onClick={() => this.onAddSwappsClick()} key='add-swapps' />
        : null
    ]

  getSwapItem = index => {
    const items = []
    if (this.state.swapCounter > 1 && index > 0) {
      items.push({type: 'button'})
    }
    if (this.state.selectedSwap && this.state.selectedSwap !== 'No') {
      items.push(this.getCategoryField(index))
      if (this.state.swapps[index] && this.state.swapps[index].selectedCategory) {
        if (this.isBrandAvailableForCategory(this.state.swapps[index].categoryOptions)) {
          items.push(this.getBrandField(index))
        }

        if (this.isProductAvailableForCategory(this.state.swapps[index].categoryOptions)) {
          items.push(this.getProductField(index))
        }
        if (this.isSpecificationsAvailableForCategory(this.state.swapps[index].categoryOptions)) {
          const numbers = [1, 2]
          numbers.forEach(item => {
            if (this.state.swapps[index].categoryOptions[`swappSpecification${item}`]) {
              items.push(this.getSpecificationIdField(item, index))
              items.push(this.getSpecificationValueField(item, index))
            }
          })
        }
      }
    }
    return items
  }

  getCategoryField = index => ({
    id: `swapps-category-${index}`,
    index,
    type: 'select',
    label: 'Kategorija',
    name: `${this.fieldName}[${index}].category`,
    options: this.props.categories
      .filter(category => category.swappPresence)
      .map(({id, title}) => ({id, label: title, value: id})),
    onChange: this.onCategoryChange
  })

  getBrandField = index => ({
    id: `swapps-brand-${index}`,
    type: 'select',
    label: 'Proizvođač',
    name: `${this.fieldName}[${index}].brand`,
    options: this.state.swapps[index].brands,
    onChange: this.onBrandChange,
    disabled: this.state.swapps[index].isBrandsLoading,
    index
  })

  getProductField = index => ({
    id: `swapps-product-${index}`,
    type: 'select',
    label: 'Model',
    name: `${this.fieldName}[${index}].product`,
    options: this.state.swapps[index].products,
    disabled: this.state.swapps[index].isProductsLoading,
    onChange: this.onProductChange,
    index
  })

  getSpecificationIdField = (number, index) => ({
    id: `swapps-specification-${index}-${number}`,
    type: 'hidden',
    name: `${this.fieldName}[${index}].specification${number}`
  })

  getSpecificationValueField = (number, index) => ({
    id: `swapps-specification-${index}-${number}-value`,
    type: 'select',
    label: this.state.swapps[index].categoryOptions && this.state.swapps[index].categoryOptions[`swappSpecification${number}`]
      ? `${this.state.swapps[index].categoryOptions[`swappSpecification${number}`].title}` : ``,
    name: `${this.fieldName}[${index}].specificationValue${number}`,
    options: this.state.swapps[index][`specificationValues${number}`],
    onChange: this.onSpecificationChange,
    index,
    number
  })

  onCategoryChange = async (e, category) => {
    if (category) {
      const value = Number(e.target.value)
      const index = category.index
      const filteredCategory = this.props.categories.filter(item => item.id === value)
      let categoryOptions = {}
      if (filteredCategory.length) {
        categoryOptions = {
          requiredAdOptions: filteredCategory[0].requiredAdOptions,
          swappSpecification1: filteredCategory[0].swappSpecification1,
          swappSpecification2: filteredCategory[0].swappSpecification2
        }
      }
      await this.saveSwappsToState(
        index,
        {
          categoryOptions,
          brands: [],
          products: [],
          specificationValues1: [],
          specificationValues2: [],
          selectedCategory: value
        }
      )

      this.props.change(this.props.formName, this.getBrandField(index).name, null)
      if (this.isBrandAvailableForCategory(categoryOptions)) {
        await this.loadBrandsToState(value, index)
      }

      this.props.change(this.props.formName, this.getProductField(index).name, null)
      this.props.change(this.props.formName, this.getSpecificationValueField(1, index).name, null)
      this.props.change(this.props.formName, this.getSpecificationValueField(2, index).name, null)

      if (this.isSpecificationsAvailableForCategory(categoryOptions)) {
        if (categoryOptions.swappSpecification1) {
          this.props.change(this.props.formName, this.getSpecificationIdField(1, index).name, categoryOptions.swappSpecification1.id)
        } else {
          this.props.change(this.props.formName, this.getSpecificationIdField(1, index).name, null)
        }
        if (categoryOptions.swappSpecification2) {
          this.props.change(this.props.formName, this.getSpecificationIdField(2, index).name, categoryOptions.swappSpecification2.id)
        } else {
          this.props.change(this.props.formName, this.getSpecificationIdField(2, index).name, null)
        }
        await this.loadSpecificationsToState(categoryOptions, index)
      }
    }
  }

  onBrandChange = async (e, brand) => {
    const value = Number(e.target.value)
    const index = brand.index
    await this.saveSwappsToState(
      index,
      {...this.state.swapps[index], products: [], selectedProduct: null, selectedBrand: value}
    )
    if (this.isProductAvailableForCategory(this.state.swapps[index].categoryOptions)) {
      this.props.change(this.props.formName, this.getProductField(index).name, null)
      await this.loadProductsToState(value, index)
    }
  }

  onProductChange = async e => {
    const value = Number(e.target.value)
    const index = Number(e.target.dataset.index)
    await this.saveSwappsToState(index, {selectedProduct: value})
  }

  onSpecificationChange = async (e, spec) => {
    if (Array.isArray(this.props.adsSwapps) && (!this.props.adsSwapps.length ||
    !this.props.adsSwapps[spec.index][`specificationValue${spec.number}`])) {
      const swappSpec = this.state.swapps[spec.index].categoryOptions[`swappSpecification${spec.number}`]
      if (swappSpec) {
        this.props.change(this.props.formName, this.getSpecificationIdField(spec.number, spec.index).name, swappSpec.id)
      }
    }
    const value = e.target.value
    if (spec.number) {
      await this.saveSwappsToState(spec.index, {[`selectedSpecification${spec.number}`]: value})
    }
  }

  loadBrandsToState = async (category, index) => {
    await this.saveSwappsToState(index, {isBrandsLoading: true})
    const api = new AdsBrandsApi()
    const brands = await api.getBrandsByCategory(category)
    await this.saveSwappsToState(
      index,
      {brands: brands.map(({id, name}) => ({id, label: name, value: id})), isBrandsLoading: false}
    )
  }

  loadSpecificationsToState = async (category, index) => {
    const numbers = [1, 2]
    numbers.forEach(async item => {
      if (category[`swappSpecification${item}`] && Array.isArray(category[`swappSpecification${item}`].options)) {
        const options = category[`swappSpecification${item}`].options.map(
          (item, index) => ({id: index, value: item, label: item})
        )
        await this.saveSwappsToState(index, {[`specificationValues${item}`]: options})
      }
    })
  }

  loadProductsToState = async (brand, index) => {
    await this.saveSwappsToState(index, {isProductsLoading: true})
    const category = Number(this.state.swapps[index].selectedCategory)
    const api = new AdsProductsApi()
    const products = await api.fetchProductByBrandAndCategory({brand, category})
    await this.saveSwappsToState(
      index,
      {products: products.map(({id, model}) => ({id, label: model, value: id})), isProductsLoading: false}
    )
  }

  isBrandAvailableForCategory = (category = {}) => {
    return category && (category.requiredAdOptions === 'BrandModel' || category.requiredAdOptions === 'BrandOnly')
  }

  isProductAvailableForCategory = (category = {}) => {
    return category && category.requiredAdOptions === 'BrandModel'
  }

  isSpecificationsAvailableForCategory = (category = {}) => {
    return category &&
      (category.requiredAdOptions === 'BrandOnly' || category.requiredAdOptions === null) &&
      (category.swappSpecification1 || category.swappSpecification2)
  }

  isNeedRenderSwap = () => Boolean(this.props.category && this.props.category.swappPresence)

  isShouldRenderAddButton = () =>
    this.state.swapCounter < this.maxSwapCount &&
    this.state.selectedSwap &&
    this.state.selectedSwap !== 'No'

  onAddSwappsClick = async () => {
    await this.setState(prevState => {
      prevState.swapps.push(this.getDefaultSwapState())
      return {...prevState, swapCounter: prevState.swapCounter + 1}
    })
  }

  onRemoveSwappsClick = async e => {
    const index = Number(e.target.dataset.index)
    await this.setState(prevState => {
      const newSwapps = prevState.swapps.filter((item, i) => index !== i)
      return {...prevState, swapCounter: prevState.swapCounter - 1, swapps: newSwapps}
    })
    this.props.arrayRemove(this.props.formName, this.fieldName, index)
  }

  render () {
    const selectedCategories = this.state.swapps.map(item => item.selectedCategory)
    const selectedBrands = this.state.swapps.map(item => item.selectedBrand)
    return this.isNeedRenderSwap()
      ? [
        <Select {...this.getSwapField()} key='swapp' />,
        <FieldArray
          component={this.renderSwapps}
          name={this.fieldName}
          key='swapps'
          swapps={this.state.swapps}
          isLoading={this.state.isLoading}
          selectedCategories={selectedCategories}
          selectedBrands={selectedBrands}
        />
      ]
      : <Hidden name={this.getSwapField().name} />
  }
}

const mapStateToProps = state => ({
  category: getCurrentSubCategory(state),
  categories: getFlattenCategories(state)
})

Container.displayName = 'Swap'

Container.propTypes = {
  category: PropTypes.shape({
    swappPresence: PropTypes.bool.isRequired
  }),
  categories: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    swappPresence: PropTypes.bool.isRequired,
    requiredAdOptions: PropTypes.oneOf(['BrandModel', 'BrandOnly', 'Services', null]),
    swappSpecification1: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.string)
    }),
    swappSpecification2: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.string)
    })
  })).isRequired,
  swapp: PropTypes.string,
  adsSwapps: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.shape({
      id: PropTypes.number.isRequired,
      requiredAdOptions: PropTypes.oneOf(['BrandModel', 'BrandOnly', 'Services', null])
    }),
    brand: PropTypes.shape({
      id: PropTypes.number.isRequired
    }),
    products: PropTypes.shape({
      id: PropTypes.number.isRequired
    }),
    specificationValue1: PropTypes.any,
    specificationValue2: PropTypes.any
  })),
  change: PropTypes.func.isRequired,
  arrayRemove: PropTypes.func.isRequired,
  formName: PropTypes.oneOf(['addAd', 'editAd'])
}

export default connect(mapStateToProps, {change, arrayRemove})(Container)
