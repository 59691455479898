import { setProducts, setProductsLoading } from '../../actions'
import { AdsProductsApi } from '../../api'

export const fetchProducts = options => async dispatch => {
  dispatch(setProductsLoading(true))
  const api = new AdsProductsApi()
  const products = await api.fetchProductByBrandAndCategory(options)
  dispatch(setProducts(products))
  dispatch(setProductsLoading(false))
}
