import { Header } from '../../Header'
import { Checkbox } from 'SRC/ui/FormElements'
import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'redux-form'

export class Group extends React.PureComponent {
  renderGroup = ({fields, items}) => items.map(
    item => <Checkbox key={item.id} {...item} name={`equipment[${item.id}]`} />
  )

  render () {
    const {id, items, title} = this.props
    return [
      <Header title={title} key={`header-${id}`} />,
      <FieldArray component={this.renderGroup} name='equipment' items={items} key={`equipment-${id}`} />
    ]
  }
}

Group.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any.isRequired
  })).isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
}

Group.defaultProps = {
  items: []
}
