import React from 'react'
import { useFormikContext, ErrorMessage } from 'formik'
import { rationalNumbers } from 'Core/masks'
import config from 'SRC/config/config.json'
import useTranslation from 'next-translate/useTranslation'

import { TextInputNew, Wrapper } from 'SRC/ui/FormElementsNew'

import { updateDiscountCardPrice } from '../functions'

export const RegularPrice = () => {
	const formik = useFormikContext()
  const { t } = useTranslation('common')

	React.useEffect(() => {
		if (Number(formik.initialValues.price) === -1) formik.setFieldValue('price', '', false)
		return () => { formik.setFieldValue('price', -1, false) }
	}, [])

	const { adForm: { adPriceTypes } } = config

	const error = formik.touched.price && formik.errors.price

	return (
		<Wrapper isError={error}>
			<TextInputNew
				id='price'
				name='price'
				title={t('Cijena')}
				isRequired
				value={formik.values.price}
				mask={rationalNumbers}
				allowedCharacters={['rational']}
				measure='€'
				onChange={async textValue => {
					console.log('regular price', textValue)
					const discountedPriceValue = Number(formik.values.discountedPrice)

					const actualValue = Number(textValue) < discountedPriceValue ? - 1 : textValue

					await formik.setFieldValue('price', actualValue)

					if (Number(formik.values.priceTypeForAddons) === adPriceTypes.discountCard.id) {
						await updateDiscountCardPrice(formik, {
							discountedPrice: formik.values.discountedPrice,
							price: actualValue
						})
					}

					if (!formik.touched.price) formik.setFieldTouched('price', true)

					formik.setErrors({
						...formik.errors,
						price: formik.touched.price && [0, -1].includes(Number(textValue))
						? `${t('Cijena')} ${t('je obavezno polje')}`
						: undefined
					})
				}}
			/>

			<ErrorMessage
				name='price'
				render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
			/>
		</Wrapper>
	)
}
