import React from 'react'
import { connect } from 'react-redux'
import { getEquipment } from 'SRC/modules/ads/equipment/selectors'
import { groups as equipmentGroups } from 'SRC/modules/ads/equipment/data'
import PropTypes from 'prop-types'
import config from 'SRC/config/config.json'

import { Group } from '../Group'

export class List extends React.PureComponent {
  groupEquipment = () => {
		const { equipment } = this.props

		const { adForm: { equipmentGroups } } = config

    const items = equipment.slice().sort((a, b) => {
      if (a.weight !== b.weight) return a.weight - b.weight

			return a.title.localeCompare(b.title)
		})

    return equipmentGroups.map(group => ({
      title: group.title,
      groupId: group.id,
      items: items.filter(item => item.grupa === group.code)
    }))
  }

  render () {
		const { equipment } = this.props

    if (equipment && equipment.length) {
			const equipmentByGroup = this.groupEquipment()

			return (
				<React.Fragment>
					{equipmentByGroup.map(group => group.items.length ? <Group {...group} key={group.id} /> : null)}
				</React.Fragment>
			)
		}

		return null

    return this.groupEquipments().map(group =>
      group.items.length ? <Group {...group} key={group.id} /> : null
    )
  }
}

const mapStateToProps = state => ({
  equipment: getEquipment(state).map(item => ({...item, label: item.title}))
})

List.propTypes = {
  equipment: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    priority: PropTypes.number,
    grupa: PropTypes.string.isRequired
  })).isRequired
}

List.defaultProps = {
  equipment: []
}

List.displayName = 'Equipment'

export default connect(mapStateToProps)(List)
