import React from 'react'

import { useFormikContext } from 'formik'
import { CheckboxNew } from 'SRC/ui/FormElementsNew'
import useTranslation from 'next-translate/useTranslation'

export const PriceOnRequest = () => {
	const formik = useFormikContext()
  const { t } = useTranslation('common')

	return (
		<div className='dodatna-oprema-checkbox'>
			<CheckboxNew
				id='price-on-request'
				title={t('Cijena na upit')}
				isSelected={formik.values.priceOnRequest}
				onChange={e => { 
					formik.setFieldValue('priceOnRequest', e.target.checked)
				}}
			/>
		</div>
	)
}
