import React from 'react'
import useTranslation from 'next-translate/useTranslation'

export const Reminder = () => {
  const { t } = useTranslation('common')

  return (
    <p className='napomena-slike'>
      <strong>{t('NAPOMENA')}: </strong>
      {/* Fotografišite predmet koji prodajte, jer nije dozvoljeno postavljati fotografije sa interneta ili fotografije iz
      oglasa drugih korisnika. Takvi oglasi neće biti objavljeni. */}
      {t('addAdImagesFootnote')}
    </p>
  )
}

