/* eslint-disable no-undef */
import PropTypes from 'prop-types'
import React from 'react'
import { AdApi } from '../../../../api'
import { Header } from '../../Header'
import { AdditionalPreloader } from 'SRC/ui/Preloader'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import { getIsAuthorized } from 'SRC/modules/users/Auth/selectors'
import { getFdWithTimestamp, getFdWithoutTimestamp } from '../../../../functions'
import { AddBtn } from '../AddBtn'
import { Item } from '../Item'
import { getPhotos, getNeedResetPhotos } from '../../../../selectors'
import { setPhotos, resetPhotos, setPhotosUploading } from '../../../../actions'
import { setError } from 'SRC/modules/ads/add/actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import withTranslation from 'next-translate/withTranslation'
import { Reminder } from '../Reminder'
import config from 'SRC/config/config.json'

const getBase64 = (file) => {
  const reader = new FileReader()
  return new Promise(resolve => {
    reader.onload = ev => {
      resolve(ev.target.result)
    }
    reader.readAsDataURL(file)
  })
}

const checkUploadFileSize = files => {
  const size = files.reduce((acc, curr) => {
    acc = acc + curr.size

    return acc
  }, 0)

  if (size >= config.adForm.maxUploadFileSizeBytes) return false

  return true
}

export class Container extends React.Component {
  constructor (props) {
    super(props)

    this.draggeditem = React.createRef()
    this.api = new AdApi()
    this.state = { isLoading: false, isUploading: false }
  }

  componentDidMount () {
    if (this.props.existingPhotos) {
      this.loadPhotos()
    } else {
      this.setState({ isLoading: false })
    }
  }

  componentDidUpdate () {
    const { needResetPhotos, resetPhotos } = this.props
    if (needResetPhotos) {
      this.loadPhotos()
      resetPhotos(false)
    }
  }

  loadPhotos = () => {
    const { existingPhotos } = this.props
    this.setState({ isLoading: true })
    if (existingPhotos && existingPhotos.length) {
      const photos = []
      const existingPhotosSorted = existingPhotos.filter(photo => photo.visible).slice().sort((photo1, photo2) => photo1.priority - photo2.priority)
      for (const photo of existingPhotosSorted) {
        photos.push({
          id: photo.id,
          fd: photo.fd,
          priority: photo.priority,
          main: photo.main,
          visible: photo.visible,
          isUploaded: false
        })
      }
      this.props.setPhotos(photos)
    }
    this.setState({ isLoading: false })
  }

  checkForPhotosLimit = uploadedPhotos => {
    const { photos } = this.props
    const visiblePhotos = photos.filter(p => p.visible)
    if (this.props.isAuthorized) {
      return true
    }
    if (uploadedPhotos.length + visiblePhotos.length > config.adForm.addAdForm.photosLimit) return false
    return true
  }

  updatePhotosPriorities = photos => {
    const visiblePhotos = photos.filter(photo => photo.visible)
    const hiddenPhotos = photos.filter(photo => !photo.visible)

    return visiblePhotos.concat(hiddenPhotos).map((photo, index) => ({
      ...photo,
      main: index === 0,
      priority: index
    }))
  }

  handleUploadedFiles = async (files = []) => {
    const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp']
    const filesWithUnsupportedFormat = files.filter(file => !supportedFormats.includes(file.type))

    if (!checkUploadFileSize(files)) {
      return this.props.setError(config.adForm.errors.MAX_FILE_SIZE_EXCEEDED)
    }

    if (filesWithUnsupportedFormat.length) {
      return this.props.setError(config.adForm.messages.unsuportedFileFormat)
    }

    if (this.checkForPhotosLimit(files)) {
      let photos = [...this.props.photos]
      const startIndex = photos.filter(p => p.visible).length
      for (const file of files) {
        const dataUrl = await getBase64(file)
        photos.push({
          fd: dataUrl,
          priority: photos.length,
          main: photos.length === 0,
          visible: true,
          isUploaded: true,
          isUploading: true
        })
      }
      let photosWithUpdatedPriorities = this.updatePhotosPriorities(photos)

      this.props.setPhotos(photosWithUpdatedPriorities)

      this.props.setPhotosUploading(true)
      const uplodedImages = await this.uploadFilesToServer(files)
      photos = this.props.photos.filter((p, index) => index < startIndex)
      for (const index in uplodedImages) {
        photos.push({
          fd: uplodedImages[index],
          priority: photos.length,
          main: photos.length === 0,
          visible: true,
          isUploaded: true,
          isUploading: false
        })
      }

      photosWithUpdatedPriorities = this.updatePhotosPriorities(photos)

      this.props.setPhotos(photosWithUpdatedPriorities)
    } else {
      this.props.setError(config.adForm.addAdForm.errors.MEDIA_LIMIT)
      this.props.setPhotos(this.props.photos)
    }
    this.props.setPhotosUploading(false)
  }

  uploadFilesToServer = async files => {
    this.setState({ isUploading: true })
    const formData = new FormData()
    for (const file of files) {
      formData.append('images', file)
    }

    const result = await this.api.uplodAdImages(formData)
    if (result.status === 'ok') {
      this.setState({ isUploading: false })
      return result.images
    } else {
      this.props.setError(result.message)
      this.setState({ isUploading: false })
      return null
    }
  }

  rotateLeft = fd => {
    const photoToRotate = this.props.photos.find(photo => photo.fd === fd)
    if (photoToRotate) {
      if (photoToRotate.isUploaded) {
        this.rotateUploadedImage(photoToRotate, 'left')
      } else {
        this.rotateExistingImage(photoToRotate, 'left')
      }
    }
  }

  rotateRight = fd => {
    const photoToRotate = this.props.photos.find(photo => photo.fd === fd)
    if (photoToRotate) {
      if (photoToRotate.isUploaded) {
        this.rotateUploadedImage(photoToRotate, 'right')
      } else {
        this.rotateExistingImage(photoToRotate, 'right')
      }
    }
  }

  rotateUploadedImage = (photo, direction) => {
    const srcKey = getFdWithoutTimestamp(photo.fd)

    const location = window.location
    const url = `${location.protocol}//${location.host}/imglambda`

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        srcKey,
        destKey: srcKey,
        rotateStep: direction === 'right' ? 0 : 2
      })
    })
      .then(res => res.text())
      .then(res => {
        const newPhotos = this.props.photos.map(p => {
          if (p.fd === photo.fd) {
            return {
              ...p,
              fd: getFdWithTimestamp(photo.fd)
            }
          } else {
            return p
          }
        })

        this.props.setPhotos(newPhotos)
      })
      .catch(err => { throw new Error(err.message) })
  }

  rotateExistingImage = (photo, direction) => {
    const { adId } = this.props
    console.log('rotating', photo, 'ad', adId)

    const url = `${location.protocol}//${location.host}/imgrotate`

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fd: photo.fd,
        adId,
        rotateStep: direction === 'right' ? 0 : 2
      })
    }).then(response => response.json())
      .then(result => {
        if (result.response && result.response.updatedMedia) {
          if (result.response.updatedMedia.length) {
            const updatedPhoto = result.response.updatedMedia.find(p => p.id === photo.id)
            const newPhotos = this.props.photos.map(p => {
              if (p.id === updatedPhoto.id) {
                return {
                  ...p,
                  fd: updatedPhoto.fd
                }
              } else {
                return p
              }
            })

            this.props.setPhotos(newPhotos)
          }
        }
      })
      .catch(err => {
        console.log('error', err)
      })
  }

  handleFilesDrop = event => {
    event.preventDefault()

    if (!this.state.isUploading) {
      const dataTransfer = event.dataTransfer
      const files = dataTransfer.files
      this.handleUploadedFiles(Array.from(files))
    }
  }

  handleFilesDragEnter = event => {
    event.preventDefault()
  }

  handleFilesDragLeave = event => {
    event.preventDefault()
  }

  handleFilesDragOver = event => {
    event.preventDefault()
  }

  handleFilesInputChanged = (event) => {
    event.preventDefault()
    this.handleUploadedFiles(Array.from(event.target.files))
  }

  handleItemDragStart = event => {
    this.draggeditem.current = event.currentTarget
  }

  handleItemDragEnter = event => {
    event.preventDefault()
    event.currentTarget.classList.add('hovered')
  }

  handleItemDragLeave = event => {
    event.preventDefault()
    if (event.target === event.currentTarget) {
      event.currentTarget.classList.remove('hovered')
    }
  }

  handleItemDragOver = event => {
    event.preventDefault()
  }

  handleItemDrop = async event => {
    event.preventDefault()

    event.currentTarget.classList.remove('hovered')
    const targetItemData = event.currentTarget.dataset

    if (event.dataTransfer.items && event.dataTransfer.items.length && !this.state.isUploading) {
      let photos = [...this.props.photos]
      const photoIndex = photos.findIndex(p => p.fd === targetItemData.fd)
      if (photoIndex > -1) {
        const files = event.dataTransfer.files
        if (this.checkForPhotosLimit(files)) {
          // Upload
          const tempPhotos = []
          for (const file of files) {
            const dataUrl = await getBase64(file)
            tempPhotos.push({
              fd: dataUrl,
              isUploaded: true,
              visible: true,
              isUploading: true
            })
          }

          photos.splice(photoIndex + 1, 0, ...tempPhotos)

          let photosWithUpdatedPriorities = this.updatePhotosPriorities(photos)

          this.props.setPhotos(photosWithUpdatedPriorities)

          this.props.setPhotosUploading(true)
          const uploadPhotos = await this.uploadFilesToServer(files)
          if (uploadPhotos && uploadPhotos.length) {
            photos = this.props.photos.filter((p, index) => index < photoIndex + 1 || index > photoIndex + uploadPhotos.length)

            photos.splice(photoIndex + 1, 0, ...uploadPhotos.map((photoFd, index) => ({
              fd: photoFd,
              isUploaded: true,
              visible: true,
              isUploading: false
            })))

            // Update priorities
            const photosWithUpdatedPriorities = this.updatePhotosPriorities(photos)

            this.props.setPhotos(photosWithUpdatedPriorities)
          }
        } else {
          this.props.setError(config.adForm.addAdForm.errors.MEDIA_LIMIT)
          this.props.setPhotos(this.props.photos)
        }
        this.props.setPhotosUploading(false)
      }
    } else {
      const sourceItemFd = this.draggeditem.current.dataset.fd

      const photos = [...this.props.photos]

      const sourcePhotoIndex = photos.findIndex(p => p.fd === sourceItemFd)
      const targetPhotoIndex = photos.findIndex(p => p.fd === targetItemData.fd)

      const sourcePhoto = photos[sourcePhotoIndex]

      photos.splice(sourcePhotoIndex, 1)
      photos.splice(targetPhotoIndex, 0, sourcePhoto)

      const photosWithUpdatedPriorities = this.updatePhotosPriorities(photos)

      this.props.setPhotos(photosWithUpdatedPriorities)

      event.currentTarget.classList.remove('hovered')
      this.draggeditem.current.classList.remove('hovered')
      this.draggeditem.current = null
    }
  }

  onIncreasePriority = fd => {
    const photos = [...this.props.photos]

    const sourcePhotoIndex = photos.findIndex(p => p.fd === fd)
    const sourcePhoto = { ...photos[sourcePhotoIndex] }

    photos[sourcePhotoIndex] = photos[sourcePhotoIndex - 1]
    photos[sourcePhotoIndex - 1] = sourcePhoto

    const photosWithUpdatedPriorities = this.updatePhotosPriorities(photos)

    this.props.setPhotos(photosWithUpdatedPriorities)
  }

  onDecreasePriority = fd => {
    const photos = [...this.props.photos]

    const sourcePhotoIndex = photos.findIndex(p => p.fd === fd)
    const sourcePhoto = { ...photos[sourcePhotoIndex] }

    photos[sourcePhotoIndex] = photos[sourcePhotoIndex + 1]
    photos[sourcePhotoIndex + 1] = sourcePhoto

    const photosWithUpdatedPriorities = this.updatePhotosPriorities(photos)

    this.props.setPhotos(photosWithUpdatedPriorities)
  }

  onPhotoDeleted = fd => {
    const { photos } = this.props

    let photosUpdated, photosWithUpdatedPriorities

    const photoToDelete = this.props.photos.find(photo => photo.fd === fd)

    // Check if is the last visible photo
    const visiblePhotos = photos.filter(photo => photo.visible)

    if (visiblePhotos.length > 1) {
      if (photoToDelete) {
        if (photoToDelete.isUploaded) {
          photosUpdated = photos.filter(p => p.fd !== fd)
        } else {
          // existing photo - hide
          photosUpdated = photos.map(photo => {
            if (photo.id === photoToDelete.id) {
              return {
                ...photo,
                visible: false
              }
            }
            return photo
          })
        }
        photosWithUpdatedPriorities = this.updatePhotosPriorities(photosUpdated)

        this.props.setPhotos(photosWithUpdatedPriorities)
      }
    }
  }

  getItemProps = (photo, index) => {
    const { isUploading } = this.state
    const { photos } = this.props
    return {
      photo,
      photosCount: photos.filter(photo => photo.visible).length,
      index,
      onIncreasePriority: isUploading ? null : this.onIncreasePriority,
      onRotateRight: isUploading ? null : this.rotateRight,
      onDecreasePriority: isUploading ? null : this.onDecreasePriority,
      onDragStart: isUploading ? null : this.handleItemDragStart,
      onPhotoDeleted: isUploading ? null : this.onPhotoDeleted,
      onDragEnter: isUploading ? null : this.handleItemDragEnter,
      onDrop: isUploading ? null : this.handleItemDrop,
      onDragLeave: isUploading ? null : this.handleItemDragLeave,
      onDragOver: isUploading ? null : this.handleItemDragOver,
      onRotateLeft: isUploading ? null : this.rotateLeft
    }
  }

  render () {
    const { photos, isMobileDevice, i18n: { t } } = this.props

    return (
      <React.Fragment>
        <Header title={t('common:Dodavanje fotografija')} key='photos-header' />

        {this.state.isLoading ? <AdditionalPreloader /> : (
          <div className='ads-add__photos-list-view'>
            {photos.map((photo, index) => photo.visible
              ? (
                <Item
                  key={photo.isUploaded ? `uploadedPhoto-${index}` : photo.id}
                  {...this.getItemProps(photo, index)}
                />
              ) : null
            )}
          </div>
        )}

        {this.state.isUploading ? <AdditionalPreloader /> : (
          <AddBtn
            onDrop={this.handleFilesDrop}
            onDragEnter={this.handleFilesDragEnter}
            onDragLeave={this.handleFilesDragLeave}
            onDragOver={this.handleFilesDragOver}
            onChange={this.handleFilesInputChanged}
            isMobileDevice={isMobileDevice}
          />
        )}

        <Reminder key='photos-reminder' />
      </React.Fragment>
    )
  }
}

Container.displayName = 'Photos'

const mapStateToProps = state => ({
  photos: getPhotos(state),
  needResetPhotos: getNeedResetPhotos(state),
  isAuthorized: getIsAuthorized(state),
  isMobileDevice: getIsMobileDevice(state)
})

Container.propTypes = {
  adId: PropTypes.number,
  photos: PropTypes.array.isRequired,
  existingPhotos: PropTypes.array.isRequired,
  needResetPhotos: PropTypes.bool.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  setPhotos: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  resetPhotos: PropTypes.func.isRequired,
  setPhotosUploading: PropTypes.func.isRequired
}

Container.defaultProps = {
  photos: []
}

const enhance = compose(
  withTranslation,
  connect(mapStateToProps, { setPhotos, setError, resetPhotos, setPhotosUploading })
)

export default enhance(Container)
