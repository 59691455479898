import React, { Component } from 'react'

import { compose } from 'recompose'
import { connect as formikConnect, ErrorMessage } from 'formik'
import { connect } from 'react-redux'
import withTranslation from 'next-translate/withTranslation'

import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'

import { getBrands } from 'SRC/modules/ads/brands/selectors'

import { AdsProductsApi } from 'SRC/modules/ads/products/api'
import { fetchProducts } from 'SRC/modules/ads/products/actions'
import { getProducts, getProductsLoading } from 'SRC/modules/ads/products/selectors'

import { getServices } from 'SRC/modules/ads/services/selectors'

import { fetchServiceTypes } from 'SRC/modules/ads/serviceTypes/actions'
import { getServiceTypes, getServiceTypesLoading } from 'SRC/modules/ads/serviceTypes/selectors'

import { SelectDropdown, Wrapper } from 'SRC/ui/FormElementsNew'


class BrandProductServices extends Component {
	productsApi = new AdsProductsApi()

	state = { productSpecifications: [] }

	async componentDidMount () {
		const { formik } = this.props

		let productSpecifications = []

		if (formik && formik.values) {
			const { brand, product } = formik.values

			if (brand !== -1 && product !== -1) {
				const defaultSpecsForProductByBrandResult = this.productsApi.fetchDefaultSpecsForProductByBrand(brand, product)

				const defaultSpecsForProductByBrand = defaultSpecsForProductByBrandResult && defaultSpecsForProductByBrandResult.ad && Array.isArray(defaultSpecsForProductByBrandResult.ad.adsSpecifications)
					? defaultSpecsForProductByBrandResult.ad.adsSpecifications
					: null

				if (Array.isArray(defaultSpecsForProductByBrand) && defaultSpecsForProductByBrand.length) {
					defaultSpecsForProductByBrand.forEach(productSpec => {
						productSpecifications.push({
							id: productSpec.specification.id,
							value: productSpec.value
						})
					})
				}
			}
		}

		if (productSpecifications.length) {
			this.setState({ productSpecifications })
		}
	}

	renderBrands = () => {
		const { category, brands, brandsLoading, fetchProducts, formik, i18n: { t } } = this.props
		
		let options = brands.map(brand => ({
			id: `${brand.id}`,
			label: brand.name,
			value: `${brand.id}`
		}))

		const disabled = !brands || !brands.length

		const selectedBrandObj = brands && brands.length ? brands.find(b => b.id === formik.values.brand) : null

		const brandError = formik.touched.brand && formik.errors.brand

		return (
				<Wrapper isError={!!brandError}>
					<SelectDropdown
						id='brand'
						title={t('common:Proizvođač')}
						isDisabled={disabled}
						isRequired
						isSearchable
						value={formik.values.brand || -1}
						options={options}
						onChange={async brandValue => {
							// console.log('selectedBrand', brandValue)
							await formik.setFieldValue('brand', typeof brandValue !== 'undefined' ? brandValue : null)
							if (!formik.touched.brand) formik.setFieldTouched('brand', true)

							if (category.requiredAdOptions === 'BrandModel') {
								formik.setFieldValue('product', -1)
								brandValue && Number(brandValue) !== -1 && fetchProducts({ category: category.id, brand: Number(brandValue) })
								this.clearOldSpecValues()
							}
						}}
					/>

					<ErrorMessage
						name='brand'
						render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
					/>
				</Wrapper>
		)
	}

	onProductChange = async productValue => {
		// console.log('selectedproduct', productValue)
		const { formik } = this.props

		if (!formik.touched.product) formik.setFieldTouched('product', true)
		formik.setFieldValue('product', typeof productValue !== 'undefined' ? productValue : null)

		this.clearOldSpecValues()

		const selectedBrand = formik.values.brand

		const defaultSpecsForProductByBrandResult = await this.productsApi.fetchDefaultSpecsForProductByBrand(
			Number(selectedBrand),
			Number(productValue)
		)

		const defaultSpecsForProductByBrand = Array.isArray(defaultSpecsForProductByBrandResult)
			? defaultSpecsForProductByBrandResult
			: null

		if (Array.isArray(defaultSpecsForProductByBrand) && defaultSpecsForProductByBrand.length) {
			const productSpecifications = []

			// console.log('formik.values.specifications', formik.values.specifications)

			const updatedSpecificationValues = Array.isArray(formik.values.specifications) ? [ ...formik.values.specifications ] : []

			defaultSpecsForProductByBrand.forEach(productSpec => {
				productSpecifications.push({
					id: productSpec.specification.id,
					value: productSpec.value
				})

				const specificationIndex = updatedSpecificationValues.findIndex(spec => spec.id === productSpec.specification.id)

				if (specificationIndex !== -1) {
					updatedSpecificationValues[specificationIndex] = {
						...formik.values.specifications[specificationIndex],
						value: `${productSpec.value}`,
						preventUpdate: true

					}
				}
			})

			// console.log('updatedSpecificationValues', updatedSpecificationValues)
			formik.setFieldValue('specifications', updatedSpecificationValues)

			this.setState({ productSpecifications })
		}
	}

	clearOldSpecValues = async () => {
    const { formik } = this.props
		const { productSpecifications } = this.state

    if (Array.isArray(productSpecifications) && productSpecifications.length && formik && formik.values && Array.isArray(formik.values.specifications)) {
			const updatedSpecificationValues = [ ...formik.values.specifications ]

			productSpecifications.forEach(productSpec => {
				const specificationIndex = updatedSpecificationValues.findIndex(spec => spec.id === productSpec.id)

				if (specificationIndex !== -1) {
					updatedSpecificationValues[specificationIndex] = {
						id: formik.values.specifications[specificationIndex].id,
						isHidden: false,
						preventUpdate: formik.values.specifications[specificationIndex].preventUpdate
					}
				}
			})

			// console.log('updatedSpecificationValues clr', updatedSpecificationValues)
			formik.setFieldValue('specifications', updatedSpecificationValues)
    }

    this.setState({ productSpecifications: [] })
  }

	renderProducts = () => {
		const { products, productsLoading, formik, i18n: { t } } = this.props

		let options = products.map(product => ({
			id: `${product.id}`,
			label: product.model,
			value: `${product.id}`
		}))

		const disabled = productsLoading || !products || !products.length || Number(formik.values.brand) === -1

		const selectedProductObj = products && products.length ? products.find(p => p.id === formik.values.product) : null

		const productError = formik.touched.product && formik.errors.product

		return (
			<Wrapper isError={!!productError}>
				<SelectDropdown
					id='product'
					title={t('common:Model')}
					isDisabled={disabled}
					isRequired
					isSearchable
					value={formik.values.product || -1}
					options={options}
					onChange={this.onProductChange}
				/>

				<ErrorMessage
					name='product'
					render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
				/>
			</Wrapper>
		)
	}

	renderServices = () => {
		const { services, fetchServiceTypes, formik, i18n: { t } } = this.props

		const disabled = !services || !services.length

		const serviceError = formik.touched.service && formik.errors.service

		const options = Array.isArray(services)
			? services.map(service => ({
				id: `${service.id}`,
				label: service.name,
				value: `${service.id}`
			}))
			: []

		return (
			<Wrapper isError={!!serviceError}>
				<SelectDropdown
					id='service'
					title={t('common:Usluga')}
					isDisabled={disabled}
					isRequired
					isSearchable
					value={formik.values.service || -1}
					options={options}
					onChange={async service => {
						await formik.setFieldValue('service', service)
						formik.setFieldValue('serviceType', -1)

						if (!formik.touched.service) formik.setFieldTouched('service', true)

						fetchServiceTypes({ service: Number(service) })
					}}
				/>

				<ErrorMessage
					name='service'
					render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
				/>
			</Wrapper>
		)
	}

	renderServiceTypes = () => {
		const { serviceTypes, serviceTypesLoading, formik, i18n: { t } } = this.props

		const disabled = !serviceTypes || !serviceTypes.length || Number(formik.values.service) === -1
		
		const serviceTypeError = formik.touched.serviceType && formik.errors.serviceType
		
		const options = Array.isArray(serviceTypes)
			? serviceTypes.map(serviceType => ({
				id: `${serviceType.id}`,
				label: serviceType.name,
				value: `${serviceType.id}`,
			}))
			: []

		return (
			<Wrapper isError={!!serviceTypeError}>
				<SelectDropdown
					id='serviceType'
					title={t('common:Tip usluge')}
					isDisabled={disabled}
					isRequired
					isSearchable
					value={formik.values.serviceType || -1}
					options={options}
					onChange={async serviceType => {
						// console.log('servicetype', serviceType)
						await formik.setFieldValue('serviceType', serviceType)
						if (!formik.touched.serviceType) formik.setFieldTouched('serviceType', true)
					}}
				/>

				<ErrorMessage
					name='serviceType'
					render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
				/>
			</Wrapper>
		)
	}

  render() {
		const { category } = this.props

		if (!category) return null

		switch (category.requiredAdOptions) {
			case 'BrandModel': return [this.renderBrands(), this.renderProducts()]
			case 'BrandOnly': return this.renderBrands()
			case 'Services': return [this.renderServices(), this.renderServiceTypes()]
			default: return null
		}
  }
}

const mapStateToProps = (state, ownProps) => {
	let category = getCurrentSubCategory(state)

  return {
		category,
		brands: getBrands(state),
		products: getProducts(state),
		productsLoading: getProductsLoading(state),
		services: getServices(state),
		serviceTypes: getServiceTypes(state),
		serviceTypesLoading: getServiceTypesLoading(state)
  }
}

const enhance = compose(
	connect(mapStateToProps, { fetchProducts, fetchServiceTypes }),
	formikConnect,
	withTranslation
)

export default enhance(BrandProductServices)
