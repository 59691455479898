import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'

export const AddBtn = ({
  onDrop,
  onDragEnter,
  onDragLeave,
  onDragOver,
  onChange,
  isMobileDevice
}) => {
  const { t } = useTranslation('common')

  return (
    <div
      className='form-group-file'
      key='photos-upload'
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
    >
      <div className='dz-message'>
        <a href='javascript:void(0)'>{t('Izaberi fotografije')}
          <input multiple className='form-group-file__input' type='file' onChange={onChange} />
        </a>
        {!isMobileDevice ? <span>{t('ili prevuci ovdje')}</span> : null}
      </div>
    </div>
  )
}

AddBtn.propTypes = {
  onChange: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onDragEnter: PropTypes.func.isRequired,
  onDragLeave: PropTypes.func.isRequired,
  onDragOver: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired
}
